export const SettingsIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 22 21" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92529 1.16675L8.81063 1.74902L8.37197 3.87964C7.67064 4.14746 7.03463 4.52725 6.45996 4.97762L4.31596 4.26074L3.73997 4.08219L3.4413 4.59652L1.96529 7.06421L1.66663 7.57861L2.10396 7.96102L3.76263 9.37207C3.70263 9.74116 3.62531 10.1062 3.62531 10.494C3.62531 10.8817 3.70263 11.2469 3.76263 11.616L2.10396 13.027L1.66663 13.4094L1.96529 13.9237L3.4413 16.3915L3.73997 16.9071L4.31596 16.7273L6.45996 16.0104C7.03463 16.4608 7.67064 16.8405 8.37197 17.1083L8.81063 19.2389L8.92529 19.8213H13.0733L13.1893 19.2389L13.6266 17.1083C14.328 16.8405 14.964 16.4608 15.5386 16.0104L17.6826 16.7273L18.2586 16.9071L18.5586 16.3915L20.0333 13.9237L20.3333 13.4094L19.8946 13.027L18.236 11.616C18.2973 11.2469 18.3733 10.8817 18.3733 10.494C18.3733 10.1062 18.2973 9.74116 18.236 9.37207L19.8946 7.96102L20.3333 7.57861L20.0333 7.06421L18.5586 4.59652L18.2586 4.08219L17.6826 4.26074L15.5386 4.97762C14.964 4.52725 14.328 4.14746 13.6266 3.87964L13.1893 1.74902L13.0733 1.16675H8.92529Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6661 10.494C13.6661 11.965 12.4715 13.1589 10.9995 13.1589C9.52747 13.1589 8.3328 11.965 8.3328 10.494C8.3328 9.02294 9.52747 7.8291 10.9995 7.8291C12.4715 7.8291 13.6661 9.02294 13.6661 10.494Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
