export const ConfigValue = {
  PORT: process.env.PORT || 3000,
  PUBLIC_REST_API_ENDPOINT: process.env.REACT_APP_BASE_URL,
  CAPTCHA: process.env.REACT_APP_CAPTCHA,
  AUTH_TOKEN_KEY: "fastoo-dashboard-auth-token",
  AUTH_USER_INFO: "fastoo-dashboard-auth-user",
  CRYPTO_SECRET_PASS: process.env.REACT_APP_SECRET_PASS || "XkhZG4fW2t2W",
  TOS: "https://www.google.com",
  PRIVACY_POLICY: "https://www.google.com",
  COOKIE_POLICY: "https://www.google.com",
};
