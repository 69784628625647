export function EditIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6026 3.15007L4.4188 10.7538C4.14755 11.0426 3.88505 11.6113 3.83255 12.0051L3.5088 14.8401C3.39505 15.8638 4.13005 16.5638 5.14505 16.3888L7.96255 15.9076C8.3563 15.8376 8.90755 15.5488 9.1788 15.2513L16.3626 7.64757C17.6051 6.33507 18.1651 4.83882 16.2313 3.01007C14.3063 1.19882 12.8451 1.83757 11.6026 3.15007Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4038 4.4187C10.7801 6.8337 12.7401 8.67995 15.1726 8.92495"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.625 19.25H18.375"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
