import { ConfigValue } from "../config";
import Cookies from "js-cookie";
import { AdminUser } from "./types";
import CryptoJS from "crypto-js";

export const AUTH_TOKEN_KEY = ConfigValue.AUTH_TOKEN_KEY;
export const AUTH_USER_INFO = ConfigValue.AUTH_USER_INFO;

export const getAuthToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get(AUTH_TOKEN_KEY);
};

export function setAuthToken(token: string) {
  Cookies.set(AUTH_TOKEN_KEY, token, { expires: 1 });
}

export function removeAuthToken() {
  Cookies.remove(AUTH_TOKEN_KEY);
}

export function checkHasAuthToken() {
  const token = Cookies.get(AUTH_TOKEN_KEY);
  if (!token) return false;
  return true;
}

export const getAuthUserInfo = () => {
  if (typeof window === undefined) {
    return null;
  }

  const value = Cookies.get(AUTH_USER_INFO);
  if (value) {
    const bytes = CryptoJS.AES.decrypt(value, ConfigValue.CRYPTO_SECRET_PASS);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};

export function setAuthUserInfo(info: AdminUser, password: string) {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify({ ...info, password }),
    ConfigValue.CRYPTO_SECRET_PASS
  ).toString();

  Cookies.set(AUTH_USER_INFO, data, {
    expires: 1,
  });
}

export function removeAuthUserInfo() {
  Cookies.remove(AUTH_USER_INFO);
}
