export const TransactionHistoryIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => {
  return (
    <svg fill="none" viewBox="0 0 22 21" {...props}>
      <path
        d="M16.3333 1.16675L20.3333 5.16675M20.3333 5.16675L16.3333 9.16675M20.3333 5.16675H5.66663C3.45749 5.16675 1.66663 6.95761 1.66663 9.16675V11.1667M5.66662 19.8334L1.66663 15.8334M1.66663 15.8334L5.66662 11.8334M1.66663 15.8334H16.3333C18.5424 15.8334 20.3333 14.0426 20.3333 11.8334V9.83341"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </svg>
  );
};
