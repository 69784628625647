import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, InputNumber, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { Fee, PaginationInput, User } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import { CurrencyDataType } from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import ToggleSwitch from "src/components/ui/switch";

const CurrencyManagementPage: FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<CurrencyDataType[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [editingKey, setEditingKey] = useState(-1);
  const [editingData, setEditingData] = useState<CurrencyDataType | null>(null);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const { mutate: getCurrencies, isLoading } = useMutation(
    client.currencies.all,
    {
      onSuccess: (data) => {
        console.log(data);
        setDataSource(
          data.map((item) => ({
            key: item.id,
            ...item,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateCurrency, isLoading: isLoadingUpdate } = useMutation(
    client.currencies.update,
    {
      onSuccess: (data) => {
        console.log(data);
        if (editingData) {
          const newData = [...dataSource];
          const index = newData.findIndex(
            (item) => editingData.key == item.key
          );
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...editingData!,
            });
            setDataSource(newData);
          } else {
            newData.push(editingData!);
            setDataSource(newData);
          }
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    isMounted && getCurrencies();
  }, [isMounted]);

  const onValueChanged = (type: string, value: boolean) => {
    console.log(type, value);
    const items = type.split("_");
    const paymentType = items[0];

    let selData = dataSource.filter((item) => item.key == Number(items[1]))[0];
    selData = {
      ...selData,
      ...(paymentType === "deposit"
        ? { depositable: value ? 1 : 0 }
        : { withdrawable: value ? 1 : 0 }),
    };

    setEditingData(selData);

    setTimeout(() => updateCurrency(selData), 100);
  };

  const columns: ColumnsType<CurrencyDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency
        </p>
      ),
      dataIndex: "code",
      key: "code",
      className: "w-auto sm:w-[45%]",
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Deposit
        </p>
      ),
      dataIndex: "depositable",
      key: "depositable",
      className: "w-auto",
      render: (_, record: { key: React.Key; depositable: number }) => (
        <div className="flex flex-row gap-2 items-center">
          <ToggleSwitch
            type={`deposit_${record.key}`}
            value={record.depositable == 1 ? true : false}
            onValueChanged={onValueChanged}
          />
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Withdrawals
        </p>
      ),
      dataIndex: "withdrawable",
      key: "withdrawable",
      className: "w-[100px]",
      render: (_, record: { key: React.Key; withdrawable: number }) => (
        <div className="flex flex-row gap-2 items-center">
          <ToggleSwitch
            type={`withdraw_${record.key}`}
            value={record.withdrawable == 1 ? true : false}
            onValueChanged={onValueChanged}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          Currency
        </p>

        <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
          <Table
            loading={isLoading || isLoadingUpdate}
            showSorterTooltip={false}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default CurrencyManagementPage;
