import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Dropdown, MenuProps, Space, Spin, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { PaginationInput, User } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import { UserDataType } from "src/lib/constants/table-type";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import DepositListPage from "src/components/transactions/deposit";
import WithdrawalListPage from "src/components/transactions/withdrawal";
import TransferListPage from "src/components/transactions/transfer";
import GeneralLimitationsPage from "src/components/limitations/general";
import DailyLimitationsPage from "src/components/limitations/daily";
import ChangePasswordPage from "src/components/settings/change-password";
import TwoFactorAuthPage from "src/components/settings/two-factor-auth";

const SettingsPage: FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const tabLists = ["Change Password", "Two-Factor Authentication"];

  const makeTabList = () => {
    const tabListsUI = tabLists.map((item, index) => (
      <Tab
        key={`tabs_${index}`}
        className={({ selected }) =>
          classNames(
            "h-full font-medium text-[14px] md:text-[16px] relative px-2 py-2 md:px-8 md:py-4 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-brand before:transition-all before:duration-300 before:ease-in-out",
            "ring-transparent ring-opacity-60 ring-offset-0 ring-offset-transparent focus:outline-none focus:ring-0",
            selected
              ? "text-primary bg-white rounded-t-[8px]"
              : "text-secondary bg-transparent"
          )
        }
      >
        {item}
      </Tab>
    ));
    return (
      <Tab.List className="flex flex-row items-center w-full h-[58px] md:h-[56px]">
        {tabListsUI}
      </Tab.List>
    );
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px] mb-4">
          Settings
        </p>

        <Tab.Group>
          {makeTabList()}
          <Tab.Panels>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <ChangePasswordPage />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <TwoFactorAuthPage />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default SettingsPage;
