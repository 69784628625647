import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
/*eslint-disable */
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import { convertPaymentSystem } from "../../lib/utils";
import CurrencyDrop from "src/components/ui/dropdown-list";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, InputNumber, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { Fee, PaginationInput, User, ParamsSort } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import { FeeDataType, UserDataType } from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import { LockIcon } from "src/components/icons/lock-icon";
import { UnlockIcon } from "src/components/icons/unlock-icon";
import Button from "src/components/ui/button";
import { BackCircleIcon } from "src/components/icons/back-circle-icon";
import UsersContainerPage from "src/components/users";
import { EditIcon } from "src/components/icons/edit-icon";
import { CloseIcon } from "src/components/icons/close-icon";
import { CheckIcon } from "src/components/icons/check-icon";
import { DeleteIcon } from "src/components/icons/delete-icon";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: FeeDataType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const FeeManagementPage: FC = () => {
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: FeeDataType) => record.key === editingKey;
  const { openModal, closeModal } = useModalAction();
  const [searchParams, setSearchParams] = useState<ParamsSort>({
    asset_code: "eur",
    channel_name: "paypal",
  });

  const [dataSource, setDataSource] = useState<FeeDataType[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [updatedData, setUpdatedData] = useState<FeeDataType | null>(null);
  const [dataCurrency, setDataCurrency] = useState<string[]>([]);
  const [dataChanels, setDataChanels] = useState<string[]>([]);

  const handleWalletSelect = (value: string, type: string): void => {
    if (type === "chanel") {
      setSearchParams({ ...searchParams, channel_name: value });
    } else {
      setSearchParams({ ...searchParams, asset_code: value });
    }
  };

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const { mutate: getFees, isLoading } = useMutation(client.fee.all, {
    onSuccess: (data) => {
      setDataSource(
        data.map((item) => ({
          key: item.id,
          ...item,
        }))
      );
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });
  const onCallback = (bSuccess: boolean) => {
    bSuccess && getFees(searchParams);
  };
  const onDelete = (key: number) => {
    openModal("FEE_DELETE_CONFIRM_VIEW", {
      id: key,
      callback: onCallback,
    });
  };

  const editOnMobile = (key: number) => {
    const selData = dataSource.filter((item) => item.key === key)[0];
    openModal("UPDATE_FEE_VIEW", { ...selData, callback: onCallBack });
  };

  const onCallBack = (data: Fee) => {
    console.log(data);
    setEditingKey(String(data.id));
    setUpdatedData({ key: Number(data.id), ...data });

    setTimeout(() => {
      updateFee(data);
    }, 100);
  };

  const { mutate: updateFee, isLoading: isLoadingUpdate } = useMutation(
    client.fee.update,
    {
      onSuccess: (data) => {
        console.log(data);
        if (updatedData) {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => editingKey == item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...updatedData!,
            });
            setDataSource(newData);
            setEditingKey("");
          } else {
            newData.push(updatedData!);
            setDataSource(newData);
            setEditingKey("");
          }
        } else {
          setEditingKey("");
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateRateFee, isLoading: isLoadUpdate } = useMutation(
    client.fee.updateRate,
    {
      onSuccess: (data) => {
        console.log(data);
        if (updatedData) {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => editingKey == item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...updatedData!,
            });
            setDataSource(newData);
            setEditingKey("");
          } else {
            newData.push(updatedData!);
            setDataSource(newData);
            setEditingKey("");
          }
        } else {
          setEditingKey("");
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: AddFees } = useMutation(client.fee.add, {
    onSuccess: (data) => {
      getFees(searchParams);
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: getFeesList } = useMutation(client.fee.listFee, {
    onSuccess: (data) => {
      setDataCurrency([...data]);
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });
  const { mutate: getChanelsList } = useMutation(client.fee.listChanels, {
    onSuccess: (data) => {
      setDataChanels([...data]);
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted) {
        console.log("called");
        getFees(searchParams);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [isMounted, searchParams]);

  useEffect(() => {
    if (isMounted) {
      getFeesList();
      getChanelsList();
    }
  }, [isMounted]);

  const edit = (record: Partial<FeeDataType> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key as string);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as FeeDataType;
      const selData = dataSource.filter((item) => item.key === key)[0];
      const rangeChanged =
        String(row.min_amount) !== String(selData.min_amount) ||
        String(row.max_amount) !== String(selData.max_amount);

      if (rangeChanged) {
        setUpdatedData(row);
        setTimeout(() => {
          updateRateFee({ ...row, id: key as number });
        }, 300);
      } else {
        setUpdatedData(row);
        setTimeout(() => {
          updateFee({ ...row, id: key as number });
        }, 300);
      }
      return;
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency Name
        </p>
      ),
      dataIndex: "asset_code",
      key: "asset_code",
      className: "w-auto",
      editable: false,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Chanel Name
        </p>
      ),
      dataIndex: "channel_name",
      key: "channel_name",
      className: "w-auto",
      editable: false,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {convertPaymentSystem(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Rate from
        </p>
      ),
      dataIndex: "min_amount",
      key: "min_amount",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            &lt; {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Rate to
        </p>
      ),
      dataIndex: "max_amount",
      key: "max_amount",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            &gt; {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Deposit, %
        </p>
      ),
      dataIndex: "deposit_fee",
      key: "deposit_fee",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Min Deposit
        </p>
      ),
      dataIndex: "min_deposit_fee",
      key: "min_deposit_fee",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Withdrawal, %
        </p>
      ),
      dataIndex: "withdraw_fee",
      key: "withdraw_fee",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Min Withdrawal
        </p>
      ),
      dataIndex: "min_withdraw_fee",
      key: "min_withdraw_fee",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          VIP Deposit, %
        </p>
      ),
      dataIndex: "vip_deposit_fee",
      key: "vip_deposit_fee",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          VIP Withdrawal, %
        </p>
      ),
      dataIndex: "vip_withdraw_fee",
      key: "vip_withdraw_fee",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          VIP Min Deposit
        </p>
      ),
      dataIndex: "min_vip_deposit_fee",
      key: "min_vip_deposit_fee",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          VIP Min Withdraw
        </p>
      ),
      dataIndex: "min_vip_withdraw_fee",
      key: "min_vip_withdraw_fee",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_: any, record: FeeDataType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="flex flex-row gap-2">
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={cancel}
            >
              <CloseIcon className="w-[20px] h-[20px] text-secondary" />
            </button>
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => save(record.key)}
            >
              <CheckIcon className="w-[20px] h-[20px] text-primary" />
            </button>
          </div>
        ) : (
          <div className="flex flex-row gap-2">
            <button
              className="w-[30px] h-[30px] flex items-center justify-center"
              onClick={() => onDelete(record.key as number)}
            >
              <DeleteIcon className="w-[20px] h-[20px] text-primary" />
            </button>
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => edit(record)}
            >
              <EditIcon className="w-[20px] h-[20px] text-primary" />
            </button>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: FeeDataType) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: "",
        editing: isEditing(record),
      }),
    };
  });

  const mobileColumns: ColumnsType<FeeDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Currency Name
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.asset_code?.toUpperCase()}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Chanel Name
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {convertPaymentSystem(selData?.channel_name)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Rate from
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                &lt; {selData.min_amount}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Rate to
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                &gt; {selData.max_amount}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Deposit, %
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.deposit_fee, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Min Deposit
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.min_deposit_fee, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Withdrawal, %
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.withdraw_fee, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Min Withdrawal
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.min_vip_withdraw_fee, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                VIP Deposit, %
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.vip_deposit_fee, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                VIP Withdrawal, %
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.vip_withdraw_fee, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                VIP Min Deposit
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.min_vip_deposit_fee, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                VIP Min Withdraw
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.min_vip_withdraw_fee, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium invisible">
                Edit
              </p>
              <a
                className="text-secondary underline hover:underline hover:text-primary text-[14px] font-manrope font-medium"
                onClick={() => editOnMobile(record.key as number)}
              >
                Edit
              </a>
            </div>
          </div>
        );
      },
    },
  ];

  const { mutate: exportLimitations } = useMutation(client.fee.export, {
    onSuccess: (data) => {
      console.log(data);
      setAlertText("Export success");
      setIsAlertOpened(true);
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        exportLimitations();
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <div className="title-block flex mb-16">
          <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
            Fee Management
          </p>
          <div className="title-actions">
            <button
              type="button"
              onClick={() => exportBtn()}
              className="button button--type3 button--gap30"
            >
              Export Excel
              <span className="btn-icon btn-icon--fill">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.79961 1.8002L12.6996 5.4002M8.79961 1.8002L5.19961 5.4002M8.79961 1.8002L8.79961 13.2002M15.9996 8.4002V16.2002L1.59961 16.2002L1.59961 8.4002"
                    stroke="#232800"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
          <div className="table-filter table-filter--flex-type">
            <div className="input table-filter__item">
              <p className="input__name input__name--type2">Currency</p>
              <CurrencyDrop
                value={searchParams.asset_code}
                options={dataCurrency ? [...dataCurrency] : []}
                onChange={handleWalletSelect}
                type="currency"
              />
            </div>
            <div className="input table-filter__item">
              <p className="input__name input__name--type2">Chanel</p>
              <CurrencyDrop
                value={searchParams.channel_name}
                options={dataChanels ? [...dataChanels] : []}
                onChange={handleWalletSelect}
                type="chanel"
              />
            </div>
          </div>
          {isMobile ? (
            <Table
              loading={isLoading || isLoadingUpdate}
              showSorterTooltip={false}
              columns={mobileColumns}
              dataSource={dataSource}
              pagination={false}
            />
          ) : (
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                loading={isLoading || isLoadingUpdate}
                showSorterTooltip={false}
                columns={mergedColumns}
                dataSource={dataSource}
                pagination={false}
                rowClassName="editable-row"
              />
            </Form>
          )}
          <div className="table-footer table-footer--inside">
            <button
              className="button button--height-30 button--auto-width button--type4"
              type="button"
              onClick={() => AddFees(searchParams)}
            >
              <span className="btn-icon btn-icon--max-width">+</span>
              Add more
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeManagementPage;
