import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../layouts/_layout";
import routes from "../config/routes";
import PrivateRoute from "../lib/private-route";
import { permissions } from "../lib/constants";
import SignInPage from "../pages/authentication/sign-in";
import NotFound from "../pages/404";
import DashboardPage from "src/pages/dashboard";
import UserManagementPage from "src/pages/dashboard/user-management";
import FeeManagementPage from "src/pages/dashboard/fee-management";
import FeeManagementBusinessPage from "src/pages/dashboard/fee-management-business";
import CurrencyManagementPage from "src/pages/dashboard/currency-management";
import NotificationManagementPage from "src/pages/dashboard/notification-management";
import TransactionsManagementPage from "src/pages/dashboard/transactions-management";
import PendingDepositsPage from "src/pages/dashboard/pending-deposits";
import PendingWithdrawalsPage from "src/pages/dashboard/pending-withdrawals";
import LimitationsPage from "src/pages/dashboard/limitations";
import LimitationsBusinessPage from "src/pages/dashboard/limitations-business";
import SettingsPage from "src/pages/dashboard/settings";
import AdminsManagementPage from "src/pages/dashboard/admins-management";
import BankAccountPage from "src/pages/dashboard/bank-account";
import FastTransferPage from "../pages/dashboard/fast-transfer";
import OurPrices from "src/pages/dashboard/our-prices";
import UnidentifiedDeposits from "src/pages/dashboard/unidentifiedDeposits";
import Reset2FAPage from "src/pages/dashboard/reset2fa";
import SmsHistoryPage from "src/pages/dashboard/smsHistory";
import EmailHistoryPage from "src/pages/dashboard/emailHistory";

const AppRoutes: FC = () => {
  return (
    <Layout>
      <Routes>
        <Route index element={<SignInPage />} />
        <Route path="*" element={<NotFound />} />
        <Route
          path={routes.dashboard}
          element={
            <PrivateRoute permissions={[permissions.DASHBOARD]}>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.users}
          element={
            <PrivateRoute
              permissions={[
                permissions.USER_MANAGEMENT,
                permissions.INDIVIDUAL_USERS,
              ]}
            >
              <UserManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.userItem}
          element={
            <PrivateRoute
              permissions={[
                permissions.USER_MANAGEMENT,
                permissions.INDIVIDUAL_USERS,
              ]}
            >
              <UserManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.usersBusiness}
          element={
            <PrivateRoute
              permissions={[
                permissions.USER_MANAGEMENT,
                permissions.BUSINESS_USERS,
              ]}
            >
              <UserManagementPage isBuisiness />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.usersBusinessItem}
          element={
            <PrivateRoute
              permissions={[
                permissions.USER_MANAGEMENT,
                permissions.BUSINESS_USERS,
              ]}
            >
              <UserManagementPage isBuisiness />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.feeManagement}
          element={
            <PrivateRoute
              permissions={[
                permissions.FEE_MANAGEMENT,
                permissions.INDIVIDUAL_USERS,
              ]}
            >
              <FeeManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.feeManagementBusiness}
          element={
            <PrivateRoute
              permissions={[
                permissions.FEE_MANAGEMENT,
                permissions.BUSINESS_USERS,
              ]}
            >
              <FeeManagementBusinessPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.currency}
          element={
            <PrivateRoute permissions={[permissions.CURRENCY]}>
              <CurrencyManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.notification}
          element={
            <PrivateRoute permissions={[permissions.NOTIFICATIONS]}>
              <NotificationManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.transactions}
          element={
            <PrivateRoute
              permissions={[
                permissions.TRANSACTIONS,
                permissions.INDIVIDUAL_USERS,
              ]}
            >
              <TransactionsManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.transactionsBusiness}
          element={
            <PrivateRoute
              permissions={[
                permissions.TRANSACTIONS,
                permissions.BUSINESS_USERS,
              ]}
            >
              <TransactionsManagementPage isBuisiness />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.pendingDeposit}
          element={
            <PrivateRoute
              permissions={[
                permissions.PENDING_DEPOSITS,
                permissions.INDIVIDUAL_USERS,
              ]}
            >
              <PendingDepositsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.pendingDepositBuisiness}
          element={
            <PrivateRoute
              permissions={[
                permissions.PENDING_DEPOSITS,
                permissions.BUSINESS_USERS,
              ]}
            >
              <PendingDepositsPage isBuisiness />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.pendingWithdrawals}
          element={
            <PrivateRoute
              permissions={[
                permissions.PENDING_WITHDRAWALS,
                permissions.INDIVIDUAL_USERS,
              ]}
            >
              <PendingWithdrawalsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.pendingWithdrawalsBuisiness}
          element={
            <PrivateRoute
              permissions={[
                permissions.PENDING_WITHDRAWALS,
                permissions.BUSINESS_USERS,
              ]}
            >
              <PendingWithdrawalsPage isBuisiness />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.limitations}
          element={
            <PrivateRoute
              permissions={[
                permissions.LIMITATIONS,
                permissions.INDIVIDUAL_USERS,
              ]}
            >
              <LimitationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.limitationsBusiness}
          element={
            <PrivateRoute
              permissions={[
                permissions.LIMITATIONS,
                permissions.BUSINESS_USERS,
              ]}
            >
              <LimitationsBusinessPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.bankAccounts}
          element={
            <PrivateRoute permissions={[permissions.BANK_ACCOUNTS]}>
              <BankAccountPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.reset2FA}
          element={
            <PrivateRoute permissions={[permissions.RESET_2FA]}>
              <Reset2FAPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.emailHistory}
          element={
            <PrivateRoute permissions={[permissions.EMAIL_2FA]}>
              <EmailHistoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.smsHistory}
          element={
            <PrivateRoute permissions={[permissions.SMS_2FA]}>
              <SmsHistoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.ourPrices}
          element={
            <PrivateRoute permissions={[permissions.OUR_PRICES]}>
              <OurPrices />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.settings}
          element={
            <PrivateRoute permissions={[permissions.SETTINGS]}>
              <SettingsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.admins}
          element={
            <PrivateRoute permissions={[permissions.ADMIN_MANAGEMENT]}>
              <AdminsManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.fastTransfer}
          element={
            <PrivateRoute>
              <FastTransferPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.unidentifiedDeposits}
          element={
            <PrivateRoute
              permissions={[permissions.MANAGE_UNIDENTIFIED_DEPOSITS]}
            >
              <UnidentifiedDeposits />
            </PrivateRoute>
          }
        />
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
