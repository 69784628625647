export const Item = ({
  title,
  value,
  noMt = false
}: {
  title: string;
  value: string | number | null | undefined;
  noMt?: boolean;
}) => (
  <div className={`grid grid-cols-3 ${!noMt ? 'mt-6' : ''}`}>
    <div className="flex flex-col gap-6">
      <div>
        <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
          {title}:
        </p>
        <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
          {value}
        </p>
      </div>
    </div>
  </div>
);