export const UserIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 20 22" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5.66414C14 7.87203 12.2094 9.66153 10 9.66153C7.79071 9.66153 6.00004 7.87203 6.00004 5.66414C6.00004 3.45625 7.79071 1.66675 10 1.66675C12.2094 1.66675 14 3.45625 14 5.66414Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 20.3213H3.33337C3.33337 19.3745 3.33337 18.4739 3.33337 17.6583C3.33337 15.4491 5.12424 13.6589 7.33337 13.6589H12.6667C14.8758 13.6589 16.6667 15.4491 16.6667 17.6583C16.6667 18.4739 16.6667 19.3745 16.6667 20.3213Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="square"
      />
    </svg>
  );
};
