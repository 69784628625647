const routes = {
  home: "/",
  dashboard: "/dashboard",
  users: "/users",
  userItem: "/users/:id",
  usersBusiness: "/users-business",
  reset2FA: "/reset-2fa",
  emailHistory: "/email-history",
  smsHistory: "/sms-history",
  usersBusinessItem: "/users-business/:id",
  transactions: "/transactions",
  transactionsBusiness: "/transactions-business",
  pendingWithdrawals: "/pending-withdrawals",
  pendingWithdrawalsBuisiness: "/pending-withdrawals-buisiness",
  pendingDeposit: "/pending-deposit",
  pendingDepositBuisiness: "/pending-deposit-buisiness",
  unidentifiedDeposits: '/unidentified-deposits',
  feeManagement: "/fee",
  admins: "/admins",
  limitations: "/limitations",
  limitationsBusiness: "/limitations-business",
  feeManagementBusiness: "/fee-business",
  notification: "/notification",
  currency: "/currency",
  bankAccounts: "bank-accounts",
  settings: "/settings",
  invoice: "/invoice",
  logout: "/logout",
  fastTransfer: "fast-money-transfer",
  ourPrices: "/our-prices",
};
export default routes;
