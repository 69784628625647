export function CurrencyUSDIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="#259970" />
      <path
        d="M12.382 6.97951C12.886 6.97951 13.4087 7.04485 13.95 7.17551C14.4913 7.30618 14.9673 7.53485 15.378 7.86151L14.034 9.49951C13.81 9.30351 13.5533 9.16351 13.264 9.07951C12.9747 8.98618 12.6807 8.93018 12.382 8.91151V11.1375L13.726 11.5295C14.342 11.7162 14.832 12.0242 15.196 12.4535C15.5693 12.8735 15.756 13.4102 15.756 14.0635C15.756 14.5582 15.6673 14.9922 15.49 15.3655C15.3127 15.7388 15.07 16.0562 14.762 16.3175C14.454 16.5788 14.0947 16.7842 13.684 16.9335C13.2733 17.0828 12.8393 17.1808 12.382 17.2275V18.2075H11.458V17.2275C10.842 17.2275 10.226 17.1435 9.61 16.9755C9.00333 16.8075 8.46667 16.5135 8 16.0935L9.484 14.4275C9.72667 14.7262 10.0207 14.9408 10.366 15.0715C10.7113 15.1928 11.0753 15.2675 11.458 15.2955V12.9295L10.436 12.6215C9.72667 12.3975 9.18533 12.0848 8.812 11.6835C8.448 11.2728 8.266 10.7128 8.266 10.0035C8.266 9.56485 8.35467 9.16818 8.532 8.81351C8.70933 8.45884 8.94267 8.15551 9.232 7.90351C9.53067 7.65151 9.87133 7.44618 10.254 7.28751C10.646 7.12884 11.0473 7.02618 11.458 6.97951V5.99951H12.382V6.97951ZM11.458 8.95351C11.1967 9.00951 10.9633 9.10751 10.758 9.24751C10.5527 9.38751 10.45 9.60685 10.45 9.90551C10.45 10.1202 10.492 10.2882 10.576 10.4095C10.66 10.5308 10.758 10.6288 10.87 10.7035C10.982 10.7688 11.094 10.8155 11.206 10.8435C11.318 10.8715 11.402 10.8948 11.458 10.9135V8.95351ZM12.382 15.2675C12.522 15.2395 12.662 15.2022 12.802 15.1555C12.9513 15.0995 13.082 15.0295 13.194 14.9455C13.306 14.8615 13.3947 14.7635 13.46 14.6515C13.5347 14.5302 13.572 14.3902 13.572 14.2315C13.572 14.0822 13.544 13.9562 13.488 13.8535C13.4413 13.7508 13.376 13.6668 13.292 13.6015C13.208 13.5268 13.11 13.4662 12.998 13.4195C12.886 13.3635 12.7693 13.3122 12.648 13.2655L12.382 13.1675V15.2675Z"
        fill="white"
      />
    </svg>
  );
}
