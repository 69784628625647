export const LogoIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 51 50"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M49.3848 19.1826L44.793 18.0557C44.5762 17.4609 44.3262 16.8652 44.0449 16.2744L46.4365 12.2881C46.7822 11.7119 46.6914 10.9736 46.2158 10.499L40.001 4.28418C39.5264 3.80859 38.7881 3.71777 38.2119 4.06348L34.2256 6.45508C33.6348 6.17383 33.0391 5.92383 32.4443 5.70703L31.3174 1.11523C31.1562 0.460938 30.5693 0 29.8945 0H21.1055C20.4307 0 19.8438 0.460938 19.6826 1.11523L18.5557 5.70703C17.9609 5.92383 17.3652 6.17383 16.7744 6.45508L12.7881 4.06348C12.2119 3.71777 11.4746 3.80859 10.999 4.28418L4.78418 10.499C4.30859 10.9736 4.21777 11.7119 4.56348 12.2881L6.95508 16.2744C6.67383 16.8652 6.42383 17.4609 6.20703 18.0557L1.61523 19.1826C0.960938 19.3438 0.5 19.9307 0.5 20.6055V29.3945C0.5 30.0693 0.960938 30.6562 1.61523 30.8174L6.20703 31.9443C6.42383 32.5391 6.67383 33.1348 6.95508 33.7256L4.56348 37.7119C4.21777 38.2881 4.30859 39.0264 4.78418 39.501L10.999 45.7158C11.4746 46.1914 12.2119 46.2822 12.7881 45.9365L16.7744 43.5449C17.3652 43.8262 17.9609 44.0762 18.5557 44.293L19.6406 48.7139L19.6826 48.8848C19.8438 49.5391 20.4307 50 21.1055 50L25.793 45.3125L29.8945 50C30.5693 50 31.1562 49.5391 31.3174 48.8848L31.3594 48.7139L32.4443 44.293C33.0391 44.0762 33.6348 43.8262 34.2256 43.5449L38.2119 45.9365C38.7881 46.2822 39.5254 46.1914 40.001 45.7158L46.2158 39.501C46.6914 39.0264 46.7822 38.2881 46.4365 37.7119L44.0449 33.7256C44.3262 33.1348 44.5762 32.5391 44.793 31.9443L49.3848 30.8174C50.0391 30.6562 50.5 30.0693 50.5 29.3945V20.6055C50.5 19.9307 50.0391 19.3438 49.3848 19.1826Z"
        fill="#C3E600"
      />
      <path
        d="M49.3848 19.1826L44.793 18.0557C44.5762 17.4609 44.3262 16.8652 44.0449 16.2744L46.4365 12.2881C46.7822 11.7119 46.6914 10.9736 46.2158 10.499L40.001 4.28418C39.5264 3.80859 38.7881 3.71777 38.2119 4.06348L34.2256 6.45508C33.6348 6.17383 33.0391 5.92383 32.4443 5.70703L31.3174 1.11523C31.1563 0.460938 30.5693 0 29.8945 0H25.5V45.6055L25.793 45.3125L29.8945 50C30.5693 50 31.1563 49.5391 31.3174 48.8848L31.3594 48.7139L32.4443 44.293C33.0391 44.0762 33.6348 43.8262 34.2256 43.5449L38.2119 45.9365C38.7881 46.2822 39.5254 46.1914 40.001 45.7158L46.2158 39.501C46.6914 39.0264 46.7822 38.2881 46.4365 37.7119L44.0449 33.7256C44.3262 33.1348 44.5762 32.5391 44.793 31.9443L49.3848 30.8174C50.0391 30.6562 50.5 30.0693 50.5 29.3945V20.6055C50.5 19.9307 50.0391 19.3438 49.3848 19.1826Z"
        fill="#C3E600"
      />
      <path
        d="M29.8945 34.3994H21.1055C20.2969 34.3994 19.6406 35.0547 19.6406 35.8643V48.7139L19.6826 48.8848C19.8437 49.5391 20.4307 50 21.1055 50H29.8945C30.5693 50 31.1563 49.5391 31.3174 48.8848L31.3594 48.7139V35.8643C31.3594 35.0547 30.7031 34.3994 29.8945 34.3994Z"
        fill="#232800"
      />
      <path
        d="M29.8945 34.3994H25.5V50H29.8945C30.5693 50 31.1563 49.5391 31.3174 48.8848L31.3594 48.7139V35.8643C31.3594 35.0547 30.7031 34.3994 29.8945 34.3994Z"
        fill="#232800"
      />
      <path
        d="M25.5 38.1836C18.2306 38.1836 12.3164 32.2695 12.3164 25C12.3164 22.3274 13.1154 19.7516 14.627 17.5513C16.1015 15.405 18.1512 13.7547 20.5544 12.779C21.006 12.5956 21.5193 12.649 21.9235 12.9212C22.3279 13.1934 22.5703 13.649 22.5703 14.1364V24.2161L25.5 26.1693L28.4297 24.2161V14.1363C28.4297 13.6489 28.6721 13.1934 29.0765 12.9211C29.4808 12.649 29.994 12.5956 30.4456 12.7789C32.8488 13.7546 34.8986 15.4049 36.373 17.5512C37.8846 19.7516 38.6836 22.3274 38.6836 25C38.6836 32.2695 32.7694 38.1836 25.5 38.1836ZM29.8945 25H29.8955H29.8945Z"
        fill="#232800"
      />
      <path
        d="M38.6836 25C38.6836 22.3273 37.8846 19.7515 36.373 17.5512C34.8985 15.4049 32.8488 13.7547 30.4456 12.779C29.994 12.5957 29.4808 12.6489 29.0765 12.9212C28.6721 13.1933 28.4297 13.6489 28.4297 14.1363V24.2161L25.5 26.1692V38.1836C32.7694 38.1836 38.6836 32.2694 38.6836 25Z"
        fill="#232800"
      />
    </svg>
  );
};
