import { useEffect, Fragment, lazy } from "react";
import { MODAL_VIEWS, useModalAction, useModalState } from "./context";
import { Dialog } from "../ui/dialog";
import { Transition } from "../ui/transition";
import { CloseCircleIcon } from "../icons/close-circle-icon";

const ActionSuccessView = lazy(() => import("./confirm-modal"));
const InvoiceView = lazy(() => import("./invoice-modal"));
const UpdateFeeView = lazy(() => import("./update-fee-modal"));
const PendingTransactionConfirmView = lazy(
  () => import("./pending-transaction-modal")
);
const PendingDepositAmountView = lazy(() => import("./deposit-amount-modal"));
const UpdateGeneralLimitationsView = lazy(
  () => import("./update-general-limitations-modal")
);
const UpdateDailyLimitationsView = lazy(
  () => import("./update-daily-limitations-modal")
);
const AddIbanAccountView = lazy(() => import("./new-iban-account"));
const AdminDeleteConfirmView = lazy(() => import("./admin-delete-modal"));
const FeeDeleteConfirmView = lazy(() => import("./fee-delete-modal"));
const AccountDeleteConfirmView = lazy(() => import("./account-delete-modal"));
const AdminSuccessView = lazy(() => import("./admin-success-modal"));
const VerifyUserView = lazy(() => import("./verify-user"));
const RejectUserView = lazy(() => import("./reject-user"));
const AcceptUnidentifyDepositView = lazy(
  () => import("./accept-unidentify-deposit")
);
const RejectUnidentifyDepositView = lazy(
  () => import("./reject-unidentify-deposit")
);
const Photo2FAView = lazy(() => import("./photo-2fa-modal"));
const Reset2FAAcceptView = lazy(() => import("./reset-accept-2fa-modal"));

function renderModalContent(view: MODAL_VIEWS) {
  switch (view) {
    case "ACTION_SUCCESS":
      return <ActionSuccessView />;
    case "INVOICE_VIEW":
      return <InvoiceView />;
    case "UPDATE_FEE_VIEW":
      return <UpdateFeeView />;
    case "PENDING_CONFIRM_VIEW":
      return <PendingTransactionConfirmView />;
    case "PENDING_DEPOSIT_AMOUNT_VIEW":
      return <PendingDepositAmountView />;
    case "UPDATE_GENERAL_LIMITATIONS_VIEW":
      return <UpdateGeneralLimitationsView />;
    case "UPDATE_DAILY_LIMITATIONS_VIEW":
      return <UpdateDailyLimitationsView />;
    case "ADMIN_DELETE_CONFIRM_VIEW":
      return <AdminDeleteConfirmView />;
    case "FEE_DELETE_CONFIRM_VIEW":
      return <FeeDeleteConfirmView />;
    case "ACCOUNT_DELETE_CONFIRM_VIEW":
      return <AccountDeleteConfirmView />;
    case "ADD_IBAN_ACCOUNT":
      return <AddIbanAccountView />;
    case "ADMIN_SUCCESS_VIEW":
      return <AdminSuccessView />;
    case "VERIFY_USER":
      return <VerifyUserView />;
    case "REJECT_USER":
      return <RejectUserView />;
    case "ACCEPT_UNIDENTIFY_DEPOSIT":
      return <AcceptUnidentifyDepositView />;
    case "REJECT_UNIDENTIFY_DEPOSIT":
      return <RejectUnidentifyDepositView />;
    case "PHOTO_2FA_VIEW":
      return <Photo2FAView />;
    case "RESET_2FA_ACCEPT_VIEW":
      return <Reset2FAAcceptView />;
    default:
      return null;
  }
}

export default function ModalsContainer() {
  const { view, isOpen } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto overflow-x-hidden md:p-4"
        onClose={closeModal}
      >
        {view && !["WITHDRAWAL_NOT_AVAILABLE"].includes(view) && (
          <button
            onClick={closeModal}
            aria-label="Close panel"
            className="hidden md:block fixed top-5 z-50 hover:text-dark right-5"
          >
            <CloseCircleIcon className="h-6 w-6 text-white" />
          </button>
        )}
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 z-40 cursor-pointer bg-dark bg-opacity-60 backdrop-blur dark:bg-opacity-80" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-110"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-110"
          >
            <div className="text-start relative z-50 inline-block min-h-screen w-full transform overflow-hidden align-middle transition-all md:min-h-[auto] md:w-auto">
              <div className="relative flex min-h-screen items-center overflow-hidden md:block md:min-h-[auto]">
                <div className="h-screen md:h-full w-full flex items-end md:items-center">
                  {view && renderModalContent(view)}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
