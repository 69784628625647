import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, InputNumber, Popover, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { Fee, PaginationInput, Permission, User } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import {
  AdminUserDataType,
  NotificationDataType,
} from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import ToggleSwitch from "src/components/ui/switch";
import { PlusIcon } from "src/components/icons/plus-icon";
import { InfoIcon } from "src/components/icons/info-icon";
import moment from "moment";
import { EditIcon } from "src/components/icons/edit-icon";
import { DeleteIcon } from "src/components/icons/delete-icon";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import Button from "src/components/ui/button";
import AdminFormPage from "src/components/admins/admin-form";

const AdminsManagementPage: FC = () => {
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<AdminUserDataType[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { openModal } = useModalAction();

  const [isNew, setIsNew] = useState(false);
  const [editingKey, setEditingKey] = useState(-1);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onCallback = (bSuccess: boolean) => {
    setIsNew(false);
    setEditingKey(-1);

    bSuccess && getList(searchParams);
  };

  const { mutate: getList, isLoading } = useMutation(client.admins.all, {
    onSuccess: (data) => {
      console.log(data);
      setTotal(data.total);
      setDataSource(
        data.admins.map((item) => ({
          key: item.id,
          full_name:
            !item.first_name && !item.last_name
              ? "-"
              : (item.first_name ?? " ") + " " + (item.last_name ?? " "),
          ...item,
        }))
      );
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  useEffect(() => {
    isMounted && getList(searchParams);
  }, [isMounted, searchParams]);

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({ ...searchParams, current_page: page });
  };

  const onAdd = () => {
    setIsNew(true);
    setEditingKey(-1);
  };

  const onDelete = (key: number) => {
    openModal("ADMIN_DELETE_CONFIRM_VIEW", {
      id: key,
      callback: onCallback,
    });
  };

  const onEdit = (key: number) => {
    console.log("edit");
    setIsNew(false);
    setEditingKey(key);
  };

  const makePermissionsInfoView = (permissions: Permission[]) => {
    const items =
      permissions.length === 0 ? (
        <p
          key={`permission_key_nothing`}
          className="font-manrope font-medium text-[16px] text-secondary"
        >
          {`${"No permissions"}`}
        </p>
      ) : (
        permissions.map((item) => (
          <p
            key={`permission_key_${item.id}`}
            className="font-manrope font-medium text-[16px] text-secondary"
          >
            {capitalizeFirstLetter(item.name)}
          </p>
        ))
      );

    return <div className="flex flex-col items-start gap-2">{items}</div>;
  };

  const columns: ColumnsType<AdminUserDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          ID
        </p>
      ),
      dataIndex: "id",
      key: "id",
      className: "w-auto",
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Full name
        </p>
      ),
      dataIndex: "full_name",
      key: "full_name",
      className: "w-auto",
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Email
        </p>
      ),
      dataIndex: "email",
      key: "email",
      className: "w-auto",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Permissions
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key; permissions: Permission[] }) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            Permissions
          </p>
          <Popover
            content={makePermissionsInfoView(record.permissions)}
            title=""
            placement="right"
            trigger="click"
          >
            <Button
              variant="icon"
              className="w-[30px] h-[30px] flex !justify-start"
            >
              <InfoIcon className="w-[15px] h-[15px] text-secondary" />
            </Button>
          </Popover>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Registration date
        </p>
      ),
      dataIndex: "created_at",
      className: "w-auto",
      key: "created_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => (
        <div className="flex flex-row">
          <button
            className="w-[30px] h-[30px] flex items-center justify-center"
            onClick={() => onEdit(record.key as number)}
          >
            <EditIcon className="w-[20px] h-[20px] text-primary" />
          </button>
          <button
            className="w-[30px] h-[30px] flex items-center justify-center"
            onClick={() => onDelete(record.key as number)}
          >
            <DeleteIcon className="w-[20px] h-[20px] text-primary" />
          </button>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<AdminUserDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const trailingActions = () => (
          <TrailingActions>
            <SwipeAction onClick={() => onDelete(record.key as number)}>
              <div className="h-full w-[80px] flex items-center text-[12px] font-medium box-border select-none">
                <div className="bg-error rounded-[12px] w-[60px] h-full shadow-card flex items-center justify-center">
                  <DeleteIcon className="w-[24px] h-[24px] text-white" />
                </div>
              </div>
            </SwipeAction>
            <SwipeAction onClick={() => onEdit(record.key as number)}>
              <div className="h-full w-[80px] flex items-center text-[12px] font-medium box-border select-none">
                <div className="bg-secondary rounded-[12px] w-[60px] h-full shadow-card flex items-center justify-center">
                  <EditIcon className="w-[24px] h-[24px] text-white" />
                </div>
              </div>
            </SwipeAction>
          </TrailingActions>
        );

        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <SwipeableList type={ListType.IOS}>
            <SwipeableListItem trailingActions={trailingActions()}>
              <div className="w-full flex flex-col gap-2 items-center justify-center">
                <div className="w-full flex flex-row items-center gap-2">
                  <p className="text-secondary text-[14px] font-manrope font-medium">
                    ID
                  </p>
                  <p className="text-primary text-[14px] font-manrope font-medium">
                    {selData ? String(selData.key) : "-"}
                  </p>
                </div>
                <div className="w-full flex flex-row gap-2 items-center justify-between">
                  <div className="w-full flex flex-col items-start gap-2">
                    <p className="text-primary text-[14px] font-manrope font-medium">
                      {selData.full_name}
                    </p>
                    <p className="text-primary text-[14px] font-manrope font-medium">
                      {selData.email}
                    </p>
                  </div>
                  <div className="w-full flex flex-col items-end gap-2">
                    <div className="w-full flex flex-row items-center justify-end">
                      <p className="text-secondary text-[14px] font-manrope font-medium">
                        Permissions
                      </p>
                      <Popover
                        content={makePermissionsInfoView(selData.permissions)}
                        title=""
                        placement="left"
                        trigger="click"
                      >
                        <Button variant="icon" className="w-[22px] h-[22px]">
                          <InfoIcon className="w-[15px] h-[15px] text-secondary" />
                        </Button>
                      </Popover>
                    </div>
                    <p className="text-primary text-[14px] font-manrope font-medium">
                      {selData.created_at}
                    </p>
                  </div>
                </div>
              </div>
            </SwipeableListItem>
          </SwipeableList>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-gray">
      {isNew || editingKey !== -1 ? (
        <AdminFormPage
          isNew={isNew}
          editingUser={
            editingKey === -1
              ? undefined
              : dataSource.filter((item) => item.key === editingKey)[0]
          }
          onCallback={onCallback}
        />
      ) : (
        <div className="w-full h-full flex-col p-2 sm:p-4">
          <div className="w-full flex flex-row items-center justify-between">
            <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
              Admin Management
            </p>
            <button
              className="flex flex-row items-center justify-between px-2 sm:px-4 py-2 gap-2 bg-white rounded-[3px] flex-shrink-0 hover:bg-[#E6E8EC]"
              onClick={onAdd}
            >
              <p className="font-manrope text-[14px] font-medium text-primary">
                Add Admin
              </p>
              <PlusIcon className="w-[18px] h-[18px] text-primary" />
            </button>
          </div>

          <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
            <Table
              loading={isLoading}
              showSorterTooltip={false}
              columns={isMobile ? mobileColumns : columns}
              dataSource={dataSource}
              pagination={{
                total,
                current: searchParams.current_page,
                onChange: onPageChange,
                position: ["bottomCenter"],
                defaultPageSize: LIST_PAGE_SIZE,
                showSizeChanger: false,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminsManagementPage;
