import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { Dropdown, MenuProps, Space, Spin, Table } from "antd";
import moment from "moment";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import {
  PaginationInput,
  User,
  TransactionsResponse,
  KwickpayHistoryResponse,
} from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import {
  transactionFilterOptions,
  kwickpayTransactionsFilterOptions,
} from "src/lib/constants";
import {
  TransactionDataType,
  UserDataType,
  CurrencyDataType,
} from "src/lib/constants/table-type";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import DepositListPage from "src/components/transactions/deposit";
import WithdrawalListPage from "src/components/transactions/withdrawal";
import TransferListPage from "src/components/transactions/transfer";
import KwickpayListPage from "src/components/transactions/kwickpay";
import InvoicePage from "src/components/invoice";
import Button from "src/components/ui/button";
import { BackCircleIcon } from "src/components/icons/back-circle-icon";
import UsersContainerPage from "src/components/users";
import SearchFilter from "src/components/search-filters/transactions-filter";
import KwickpaySearchFilter from "src/components/search-filters/kwickpay-transact-filter";

interface ITransactionsManagementPage {
  isBuisiness?: boolean;
}

const TransactionsManagementPage: FC<ITransactionsManagementPage> = ({
  isBuisiness,
}) => {
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });
  const [searchText, setSearchText] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [selectedUserID, setSelectedUserID] = useState(-1);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [dataSource, setDataSource] = useState<UserDataType[]>([]);
  const [total, setTotal] = useState(0);
  const [totalUsers, setTotalUsers] = useState<User[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [invoiceData, setInvoiceData] = useState<TransactionDataType | null>(
    null
  );

  const [currencies, setCurrencies] = useState<CurrencyDataType[]>([]);

  const [filter, setFilter] = useState("user_id");

  const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);

  const now = new Date();
  const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

  const [startDate, endDate] = dateRangeValue || [];

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    containerRef.current && setContainerWidth(containerRef.current.offsetWidth);
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onSelectedInvoiceData = (data: TransactionDataType) => {
    setInvoiceData(data);
  };

  const onBackFromInvoice = () => {
    setInvoiceData(null);
  };

  const tabLists = ["Deposit", "Withdrawals", "Transfer"];

  const getExportApi = (): ((
    input: PaginationInput
  ) => Promise<TransactionsResponse | KwickpayHistoryResponse>) => {
    if (isBuisiness) {
      switch (selectedTabIndex) {
        case 1:
          return client.transactions.buisinessWithdrawal;
        default:
          return client.transactions.buisinessDeposit;
      }
    }
    switch (selectedTabIndex) {
      case 1:
        return client.transactions.individualWithdrawal;
      case 2:
        return client.transactions.kwickpayIndividual;
      default:
        return client.transactions.individualDeposit;
    }
  };

  const { mutate: exportTransactions } = useMutation(getExportApi(), {
    onSuccess: (data) => {
      setAlertText("Export success");
      setIsAlertOpened(true);
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        exportTransactions({
          ...searchParams,
          search_value: searchText || undefined,
          search_field: searchText ? filter : undefined,
          start_date: startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : undefined,
          end_date: endDate ? moment(endDate).format("yyyy-MM-DD") : undefined,
          export: 1,
        });
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const { mutate: getCurrencies } = useMutation(client.currencies.all, {
    onSuccess: (data) => {
      console.log(data);
      setCurrencies(
        data.map((item) => ({
          key: item.id,
          ...item,
        }))
      );
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  useEffect(() => {
    isMounted && getCurrencies();
  }, [isMounted]);

  const makeTabList = () => {
    const tabListsUI = tabLists.map((item, index) => (
      <Tab
        key={`tabs_${index}`}
        className={({ selected }) =>
          classNames(
            "h-full font-medium text-[14px] md:text-[16px] relative px-2 py-2 md:px-8 md:py-4 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-brand before:transition-all before:duration-300 before:ease-in-out",
            "ring-transparent ring-opacity-60 ring-offset-0 ring-offset-transparent focus:outline-none focus:ring-0",
            selected
              ? "text-primary bg-white rounded-t-[8px]"
              : "text-secondary bg-transparent"
          )
        }
      >
        {item}
      </Tab>
    ));
    return (
      <Tab.List className="flex flex-row items-center w-full h-[58px] md:h-[56px]">
        {tabListsUI}
      </Tab.List>
    );
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full flex flex-col">
        {selectedUser == null ? (
          <div
            className={`${
              invoiceData && "hidden"
            } w-full h-full flex-col p-2 sm:p-4`}
          >
            {" "}
            <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
              Transactions
            </p>
            <div className="mt-4 flex items-center justify-between w-full mb-4">
              {/* <SearchUserIDBox
                searchText={searchText}
                setSearchText={setSearchText}
              /> */}
              {selectedTabIndex === 2 ? (
                <KwickpaySearchFilter
                  options={kwickpayTransactionsFilterOptions}
                  value={filter}
                  onChange={setFilter}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  currencies={currencies}
                />
              ) : (
                <SearchFilter
                  options={transactionFilterOptions}
                  value={filter}
                  onChange={setFilter}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  currencies={currencies}
                />
              )}
              <div className="flex gap-2">
                <DateRangePicker
                  format="dd/MM/yyyy"
                  size="lg"
                  block
                  placement="bottomEnd"
                  style={{ width: "308px" }}
                  value={dateRangeValue}
                  onChange={setDateRangeValue}
                  defaultCalendarValue={[prevMonth, now]}
                  ranges={[]}
                />
                <button
                  type="button"
                  onClick={() => exportBtn()}
                  className="button button--type3 button--gap30"
                >
                  Export Excel
                  <span className="btn-icon btn-icon--fill">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.79961 1.8002L12.6996 5.4002M8.79961 1.8002L5.19961 5.4002M8.79961 1.8002L8.79961 13.2002M15.9996 8.4002V16.2002L1.59961 16.2002L1.59961 8.4002"
                        stroke="#232800"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <Tab.Group
              selectedIndex={selectedTabIndex}
              onChange={(tabIdx: number) => setSelectedTabIndex(tabIdx)}
            >
              {makeTabList()}
              <Tab.Panels>
                <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
                  <DepositListPage
                    searchText={searchText}
                    onSelectedInvoiceData={onSelectedInvoiceData}
                    onSelectedUser={setSelectedUser}
                    searchField={filter}
                    startDate={startDate}
                    endDate={endDate}
                    isBuisiness={isBuisiness}
                  />
                </Tab.Panel>
                <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
                  <WithdrawalListPage
                    searchText={searchText}
                    onSelectedInvoiceData={onSelectedInvoiceData}
                    onSelectedUser={setSelectedUser}
                    searchField={filter}
                    startDate={startDate}
                    endDate={endDate}
                    isBuisiness={isBuisiness}
                  />
                </Tab.Panel>
                <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
                  <KwickpayListPage
                    searchText={searchText}
                    onSelectedInvoiceData={onSelectedInvoiceData}
                    onSelectedUser={setSelectedUser}
                    searchField={filter}
                    startDate={startDate}
                    endDate={endDate}
                    isBuisiness={isBuisiness}
                  />
                </Tab.Panel>
                {/*
                  <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
                    <TransferListPage
                      searchText={searchText}
                      onSelectedUser={setSelectedUser}
                    />
                  </Tab.Panel>
                  */}
              </Tab.Panels>
            </Tab.Group>
          </div>
        ) : (
          <div
            className={`${
              invoiceData && "hidden"
            } flex flex-col w-full p-2 sm:p-4`}
          >
            <div className="flex flex-row items-center justify-between sm:justify-start gap-4">
              <div className="flex flex-row items-center gap-2">
                <Button
                  variant="icon"
                  className="w-[24px] bg-transparent h-[24px]"
                  onClick={() => {
                    setSelectedUserID(-1);
                    setSelectedUser(null);
                  }}
                >
                  <BackCircleIcon className="w-[24px] h-[24px] text-primary" />
                </Button>
                <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[38px] sm:leading-[48px]">
                  {!selectedUser?.data.first_name &&
                  !selectedUser?.data.last_name
                    ? "-"
                    : (selectedUser?.data.first_name ?? " ") +
                      " " +
                      (selectedUser?.data.last_name ?? " ")}
                </p>
              </div>
              <p
                className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getStatusStyle(
                  selectedUser.status.name
                )}`}
              >
                {capitalizeFirstLetter(selectedUser.status.name)}
              </p>
            </div>
            <UsersContainerPage
              user={selectedUser}
              onSelectedInvoiceData={onSelectedInvoiceData}
            />
          </div>
        )}
      </div>
      {invoiceData && (
        <InvoicePage data={invoiceData!} callback={onBackFromInvoice} />
      )}
    </div>
  );
};

export default TransactionsManagementPage;
