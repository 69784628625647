import { FC } from "react";
import { numberFormat } from "../../lib/utils";
import { ICustomTooltipProps } from "./types";

// ================================================:
const CustomTooltip: FC<ICustomTooltipProps> = ({ active, payload, data }) => {
  const balanceList = data;

  if (active && payload !== undefined) {
    const currentItem = balanceList?.find(
      (el) => el.asset_code === payload[0]?.name
    );

    return (
      <>
        <div className="pie-chart-tooltip">
          <h4 className="pie-chart-tooltip__content">
            {numberFormat(Number(currentItem?.balance), "en-EN")}{" "}
            {currentItem?.asset_code.toUpperCase()}
          </h4>
          <h4 className="pie-chart-tooltip__content">
            $ {numberFormat(Number(currentItem?.balance_usd), "en-EN")}
          </h4>
        </div>
        <p className="pie-chart-tooltip-brd" />
      </>
    );
  }

  return null;
};

export default CustomTooltip;
