import { FC, useState } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import OurPricesTable from "./our-prices-table";

interface ILanguageTabs {
  selected: string;
  setSelected: (val: string) => void;
}

const languages = [
  {
    key: "en",
    name: "English",
  },
  {
    key: "ge",
    name: "Georgian",
  },
  {
    key: "ru",
    name: "Russian",
  },
];

const LanguageTabs: FC<ILanguageTabs> = ({ selected, setSelected }) => {
  return (
    <div className="inline-flex my-[24px] bg-[#DADADA] p-[3px] rounded-[12px]">
      {languages.map((item) => {
        const isActive = item.key === selected;
        return (
          <button
            type="button"
            onClick={() => setSelected(item.key)}
            className={`flex h-[34px] items-center justify-center px-[20px] rounded-[8px] text-[14px] font-medium text-${
              isActive ? "white" : "primary"
            } ${isActive && "bg-primary"}`}
          >
            {item.name}
          </button>
        );
      })}
    </div>
  );
};

export default LanguageTabs;
