export const SpinnerIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99991 0.454544C9.49785 0.454544 9.09082 0.861575 9.09082 1.36364V5C9.09082 5.50207 9.49785 5.9091 9.99991 5.9091C10.502 5.9091 10.909 5.50207 10.909 5V1.36364C10.909 0.861575 10.502 0.454544 9.99991 0.454544Z"
        fill="currentColor"
      />
      <path
        d="M9.99991 15.9091C9.49785 15.9091 9.09082 16.3161 9.09082 16.8182V18.6364C9.09082 19.1384 9.49785 19.5455 9.99991 19.5455C10.502 19.5455 10.909 19.1384 10.909 18.6364V16.8182C10.909 16.3161 10.502 15.9091 9.99991 15.9091Z"
        fill="currentColor"
      />
      <path
        d="M19.0914 9.54546H15.455C14.9529 9.54546 14.5459 9.95249 14.5459 10.4545C14.5459 10.9566 14.9529 11.3636 15.455 11.3636H19.0914C19.5934 11.3636 20.0004 10.9566 20.0004 10.4545C20.0004 9.95249 19.5934 9.54546 19.0914 9.54546Z"
        fill="currentColor"
      />
      <path
        d="M5.45455 10.4545C5.45455 9.95249 5.04752 9.54546 4.54545 9.54546H0.909091C0.40703 9.54546 0 9.95249 0 10.4545C0 10.9566 0.40703 11.3636 0.909091 11.3636H4.54545C5.04752 11.3636 5.45455 10.9566 5.45455 10.4545Z"
        fill="currentColor"
      />
      <path
        d="M17.0705 3.38345C16.7155 3.02841 16.1399 3.02841 15.7849 3.38345L13.2135 5.95478C12.8585 6.30981 12.8585 6.88539 13.2135 7.24042C13.3911 7.41794 13.6237 7.50673 13.8563 7.50673C14.089 7.50673 14.3217 7.418 14.4992 7.24042L17.0705 4.66908C17.4255 4.31405 17.4255 3.73848 17.0705 3.38345Z"
        fill="currentColor"
      />
      <path
        d="M5.50052 13.6687L2.92931 16.24C2.57434 16.595 2.57434 17.1706 2.92931 17.5256C3.10683 17.7031 3.33949 17.7919 3.57216 17.7919C3.80483 17.7919 4.03749 17.7031 4.21501 17.5256L6.78628 14.9543C7.14125 14.5993 7.14125 14.0237 6.78628 13.6687C6.43119 13.3136 5.85561 13.3136 5.50052 13.6687Z"
        fill="currentColor"
      />
      <path
        d="M4.21499 3.38345C3.85996 3.02842 3.28439 3.02848 2.92936 3.38345C2.57433 3.73848 2.57433 4.31406 2.92936 4.66909L5.50063 7.24043C5.67821 7.41794 5.91081 7.50673 6.14348 7.50673C6.37609 7.50673 6.60881 7.41794 6.78627 7.24049C7.1413 6.88546 7.1413 6.30988 6.78627 5.95485L4.21499 3.38345Z"
        fill="currentColor"
      />
    </svg>
  );
};
