export const PlusIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 18 19" stroke="currentColor" {...props}>
      <path
        d="M9.9016 10.4006L14.9588 10.4061C15.1805 10.4061 15.3931 10.318 15.5499 10.1612C15.7067 10.0045 15.7948 9.79181 15.7948 9.57009C15.7948 9.34837 15.7068 9.13574 15.55 8.97897C15.3932 8.8222 15.1806 8.73414 14.9589 8.73415L9.90171 8.7404L9.90796 3.68322C9.90798 3.4615 9.81991 3.24887 9.66315 3.0921C9.50638 2.93533 9.29374 2.84727 9.07202 2.84729C8.85031 2.8473 8.63766 2.93539 8.48087 3.09218C8.32408 3.24897 8.23599 3.46162 8.23597 3.68334L8.2415 8.74052L3.18432 8.73499C3.07441 8.73455 2.9655 8.75587 2.86386 8.79773C2.76223 8.83959 2.66989 8.90116 2.59216 8.97888C2.51444 9.05661 2.45287 9.14895 2.41101 9.25058C2.36915 9.35222 2.34782 9.46113 2.34827 9.57104C2.34781 9.68095 2.36912 9.78986 2.41096 9.89149C2.45281 9.99312 2.51436 10.0855 2.59208 10.1632C2.66979 10.2409 2.76212 10.3024 2.86375 10.3443C2.96538 10.3861 3.07429 10.4074 3.1842 10.407L8.24138 10.4007L8.23514 15.4579C8.23468 15.5678 8.25598 15.6767 8.29783 15.7784C8.33968 15.88 8.40123 15.9723 8.47895 16.05C8.55666 16.1277 8.64899 16.1893 8.75062 16.2312C8.85225 16.273 8.96116 16.2943 9.07107 16.2938C9.18098 16.2943 9.28989 16.273 9.39153 16.2311C9.49316 16.1892 9.5855 16.1277 9.66323 16.05C9.74095 15.9722 9.80252 15.8799 9.84438 15.7782C9.88624 15.6766 9.90757 15.5677 9.90712 15.4578L9.9016 10.4006Z"
        fill="currentColor"
      />
    </svg>
  );
};
