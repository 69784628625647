import {
  FC,
  useContext,
  useEffect,
  useState,
  useRef,
  forwardRef,
  Ref,
  useImperativeHandle,
} from "react";
import QRCode from "react-qr-code";
import Input from "../ui/input";
import { InfoIcon } from "../icons/info-icon";
import {
  AuthenticatorAppURL,
  getCurrencyInfo,
  getErrorMessage,
} from "src/lib/utils";
import Button from "../ui/button";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import useAuth from "src/hooks/use-auth";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";
export interface ConfigurationItem {
  getCode: () => string;
}

const TwoAuthSettingsConfiguration = forwardRef(
  (
    props: {
      secretKey: string;
      qrCode: string;
      isEnabled: number;
      set2FAEnabled: any;
      setShowConfiguration: any;
    },
    ref: Ref<ConfigurationItem>
  ) => {
    const textRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState("");
    const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
    const { getCurrrentUser, setCurrentUser } = useAuth();

    const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (val.length > 6) {
        return;
      }

      const re = /^[0-9\b]+$/;
      if (val === "" || re.test(val)) {
        setValue(val);
      }
    };

    function getCode() {
      return value;
    }

    useImperativeHandle(ref, () => ({ getCode }));

    const goAuthenticatorApp = (url: string) => {
      window.open(url, "_blank");
    };

    const { mutate: enable2FA, isLoading: isLoadingEnable2FA } = useMutation(
      client.google2FA.enable,
      {
        onSuccess: (data) => {
          console.log(data);
          setCurrentUser(
            { ...getCurrrentUser(), google2fa_enabled: 1 },
            getCurrrentUser().password
          );
          props.set2FAEnabled(1);
          props.setShowConfiguration(false);
        },
        onError: (error: any) => {
          if (error.code === "ERR_NETWORK") {
            setAlertText(NetworkDisconnectMsg);
          } else {
            if (error.response) {
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(APISomethingWrongMsg);
            }
          }
          setIsAlertOpened(true);
        },
      }
    );

    const { mutate: disable2FA, isLoading: isLoadingDisable2FA } = useMutation(
      client.google2FA.disable,
      {
        onSuccess: (data) => {
          console.log(data);
          setCurrentUser(
            { ...getCurrrentUser(), google2fa_enabled: 0 },
            getCurrrentUser().password
          );
          props.set2FAEnabled(0);
          props.setShowConfiguration(false);
        },
        onError: (error: any) => {
          if (error.code === "ERR_NETWORK") {
            setAlertText(NetworkDisconnectMsg);
          } else {
            if (error.response) {
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(APISomethingWrongMsg);
            }
          }
          setIsAlertOpened(true);
        },
      }
    );

    const onConfirm = () => {
      if (props.isEnabled) {
        disable2FA({ totp: value });
      } else {
        enable2FA({ totp: value });
      }
    };

    return (
      <div className="flex flex-col gap-4 w-full md:w-[567px] md:px-4 md:py-8">
        {!props.isEnabled && (
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-row items-center gap-4">
              <div className="flex items-center justify-center bg-active w-8 h-8 rounded-full">
                <p className="font-manrope text-primary text-[14px] font-medium leading-[22px]">
                  1
                </p>
              </div>
              <p className="font-manrope text-primary text-[16px] font-medium leading-[24px]">
                Download 2FA App
              </p>
            </div>
            <p className="font-manrope text-secondary text-[14px] font-light leading-[21px]">
              You should download and install an authenticator application, such
              as Google Authenticator or Authy, on your mobile device. This app
              will generate one-time 6-digit access codes, which are required
              for logging into your account.
            </p>
            <div className="flex flex-row gap-2 md:gap-8 items-center justify-between sm:justify-start">
              <button
                className="bg-google-play w-[50%] max-w-[136px] aspect-[136/40] sm:w-[136px] bg-cover hover:opacity-80"
                onClick={() => goAuthenticatorApp(AuthenticatorAppURL.android)}
              />
              <button
                className="bg-app-store w-[50%] max-w-[136px] aspect-[136/40] sm:w-[136px] bg-cover hover:opacity-80"
                onClick={() => goAuthenticatorApp(AuthenticatorAppURL.ios)}
              />
            </div>
            <div className="flex flex-row items-center gap-4 mt-4">
              <div className="flex items-center justify-center bg-active w-8 h-8 rounded-full">
                <p className="font-manrope text-primary text-[14px] font-medium leading-[22px]">
                  2
                </p>
              </div>
              <p className="font-manrope text-primary text-[16px] font-medium leading-[24px]">
                Save 2FA Backup Key and Scan QR Code
              </p>
            </div>
            <p className="font-manrope text-secondary text-[14px] font-light leading-[21px]">
              Please, backup your 2FA recovery key to some offline storage,
              write down on a paper. If your mobile device gets lost, stolen or
              erased, you will need this key to recover your 2FA access.
            </p>
            <div className="flex flex-col md:flex-row gap-4 md:gap-8 mt-4 mb-4">
              <div className="max-h-[150px] h-full max-w-[150px] w-full md:m-auto">
                <img src={props.qrCode} />
              </div>
              <div className="flex flex-col w-full md:pr-10 gap-2">
                <p className="font-manrope text-secondary text-[16px] font-medium leading-[24px]">
                  Backup / Secret Key
                </p>
                <Input
                  ref={textRef}
                  label=""
                  className="w-full"
                  value={props.secretKey}
                  inputClassName="text-success font-manrope text-[16px] font-medium !h-[58px]"
                />
                <div className="flex flex-row items-center gap-4 bg-error bg-opacity-10 p-4 rounded-[12px]">
                  <InfoIcon className="w-[18px] h-[18px] text-error flex-shrink-0" />
                  <p className="text-error font-manrope text-[14px] font-medium">
                    Please, write down or print a copy of the key above and
                    store it safely.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-row w-full items-end gap-2 sm:gap-4">
          <Input
            label="2FA Code from Authenticator App"
            className="w-full md:!w-[50%]"
            placeholder="Enter a 6-digit 2FA code"
            inputClassName="font-manrope text-[16px] font-medium !h-[58px]"
            value={value}
            onChange={onCodeChange}
            error={
              value.length > 0 && value.length < 6
                ? "Please input valid code!"
                : undefined
            }
          />
          <Button
            variant="validate"
            disabled={value.length >= 0 && value.length < 6}
            onClick={onConfirm}
            className={`${
              value.length > 0 && value.length < 6 ? "mb-[32px]" : "mb-0"
            } transition-none rounded-[12px] font-manrope text-[14px] font-bold leading-[24px] w-[118px] h-[58px]`}
            isLoading={isLoadingEnable2FA || isLoadingDisable2FA}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  }
);
TwoAuthSettingsConfiguration.displayName = "TwoAuthSettingsConfiguration";

export default TwoAuthSettingsConfiguration;
