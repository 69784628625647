export const FastTransferIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
        <path d="M18.7923 10.3582V6.45326C18.7923 2.75909 17.9306 1.83325 14.4656 1.83325H7.53565C4.07065 1.83325 3.20898 2.75909 3.20898 6.45326V16.7749C3.20898 19.2132 4.54733 19.7908 6.16983 18.0491L6.17898 18.0399C6.93064 17.2424 8.07647 17.3066 8.72731 18.1774L9.65315 19.4149" stroke="#7F8172" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.33398 6.41675H14.6673" stroke="#7F8172" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.25 10.0833H13.75" stroke="#7F8172" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.6928 13.5394L13.4478 16.7844C13.3195 16.9128 13.2003 17.1511 13.1728 17.3253L12.9986 18.5628C12.9344 19.0119 13.2461 19.3236 13.6953 19.2594L14.9328 19.0853C15.107 19.0578 15.3545 18.9386 15.4736 18.8103L18.7186 15.5653C19.2778 15.0061 19.5436 14.3553 18.7186 13.5303C17.9028 12.7144 17.2519 12.9803 16.6928 13.5394Z" stroke="#7F8172" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.2227 14.0068C16.4977 14.9968 17.2677 15.7668 18.2577 16.0418" stroke="#7F8172" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    );
};
