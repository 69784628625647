export const PendingWithdrawalIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => {
  return (
    <svg fill="none" viewBox="0 0 22 22" {...props}>
      <path
        d="M11 7.33325V1.83325L9.16663 3.66659"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 1.83325L12.8333 3.66659"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41667 11C2.75 11 2.75 12.6408 2.75 14.6667V15.5833C2.75 18.1133 2.75 20.1667 7.33333 20.1667H14.6667C18.3333 20.1667 19.25 18.1133 19.25 15.5833V14.6667C19.25 12.6408 19.25 11 15.5833 11C14.6667 11 14.41 11.1925 13.9333 11.55L12.9983 12.54C11.9167 13.695 10.0833 13.695 8.9925 12.54L8.06667 11.55C7.59 11.1925 7.33333 11 6.41667 11Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58337 11V9.16662C4.58337 7.32412 4.58337 5.80245 7.33337 5.53662"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4166 11V9.16662C17.4166 7.32412 17.4166 5.80245 14.6666 5.53662"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
