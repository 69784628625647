import { FC, Fragment, useState } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import UserAccountInfoPage from "./acccount";
import AmlInfo from "./aml-info";
import UserTransactionsPage from "./transactions";
import UserPaymentMethodsPage from "./payment-methods";
import UserBalancesPage from "./balance";
import UserLimitationsPage from "./limitations";
import { User } from "src/api/types";

type UsersContainerPageProps = {
  user: User;
  onSelectedInvoiceData: any;
};

const UsersContainerPage: FC<UsersContainerPageProps> = ({
  user,
  onSelectedInvoiceData,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [userSettings, setUserSettings] = useState({});

  const tabLists = [
    "Account",
    "AML Info",
    "Transaction History",
    "Payment Methods",
    "Balances",
    "Limitations",
  ];

  console.log(userSettings);

  const makeTabList = () => {
    const tabListsUI = tabLists.map((item, index) => (
      <Tab
        key={`tabs_${index}`}
        className={({ selected }) =>
          classNames(
            "h-full font-medium text-[14px] md:text-[16px] relative px-2 py-2 md:px-8 md:py-4 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-brand before:transition-all before:duration-300 before:ease-in-out",
            "ring-transparent ring-opacity-60 ring-offset-0 ring-offset-transparent focus:outline-none focus:ring-0",
            selected
              ? "text-primary bg-white rounded-t-[8px]"
              : "text-secondary bg-transparent"
          )
        }
      >
        {item}
      </Tab>
    ));
    return (
      <Tab.List className="flex flex-row items-center w-full h-[58px] md:h-[56px]">
        {tabListsUI}
      </Tab.List>
    );
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="flex flex-col mt-2 sm:mt-4">
        <Tab.Group
          selectedIndex={selectedTabIndex}
          onChange={(tabIdx: number) => setSelectedTabIndex(tabIdx)}
        >
          {makeTabList()}
          <Tab.Panels>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <UserAccountInfoPage user={user} />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <AmlInfo user={user} />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <UserTransactionsPage
                user={user}
                onSelectedInvoiceData={onSelectedInvoiceData}
              />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <UserPaymentMethodsPage user={user} />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <UserBalancesPage user={user} />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <UserLimitationsPage user={user} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default UsersContainerPage;
