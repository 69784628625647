import { FC, useState, useRef, useEffect } from "react";
import { convertPaymentSystem } from "../../lib/utils";

interface ICurrencyFilter {
  value: string;
  options: { code: string; name: string }[];
  onChange: (value: string) => void;
  filterName: string;
}

const CurrencyDrop: FC<ICurrencyFilter> = (props) => {
  const { value, options, onChange, filterName } = props;
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent): boolean | undefined => {
    if (node?.current?.contains(event.target as Node)) {
      return false;
    }

    setOpen(false);
    return true;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleChange = (selectedValue: string): void => {
    onChange(selectedValue);
    setOpen(false);
  };

  const handleSelectToggel = (): void => {
    setOpen(!open);
  };

  const selected = options.find((item) => item.code === value);

  return (
    <div className={`select ${open ? "active" : ""}`} ref={node}>
      <button
        type="button"
        className="select__current "
        onClick={handleSelectToggel}
      >
        <span className="select__current-text">
          {selected?.name
            ? selected?.name
            : `select by ${convertPaymentSystem(filterName)?.toLowerCase()}`}
        </span>
        <span className="select__current-arrow">
          <svg
            className="stroke"
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
          </svg>
        </span>
      </button>
      {open && options && (
        <div className="select__drop">
          <div className="select__drop-scroll">
            <div className="select-drop__item">
              <ul>
                {options?.map(({ code, name }) => (
                  <li key={code}>
                    <button type="button" onClick={() => handleChange(code)}>
                      {name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CurrencyDrop.displayName = "CurrencyDrop";
export default CurrencyDrop;
