export const LockIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 21 21" {...props}>
      <path
        d="M5.25 8.75V7C5.25 4.10375 6.125 1.75 10.5 1.75C14.875 1.75 15.75 4.10375 15.75 7V8.75"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.875 19.25H6.125C2.625 19.25 1.75 18.375 1.75 14.875V13.125C1.75 9.625 2.625 8.75 6.125 8.75H14.875C18.375 8.75 19.25 9.625 19.25 13.125V14.875C19.25 18.375 18.375 19.25 14.875 19.25Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
