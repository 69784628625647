import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, InputNumber, Popover, Table, Select } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import {
  Fee,
  PaginationInput,
  Permission,
  User,
  BankAccount,
} from "src/api/types";
import { EditIcon } from "src/components/icons/edit-icon";
import { CloseIcon } from "src/components/icons/close-icon";
import { CheckIcon } from "src/components/icons/check-icon";
import SearchUserIDBox from "src/components/shared/search-box";
import {
  AdminUserDataType,
  AccountDataType,
  NotificationDataType,
} from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import ToggleSwitch from "src/components/ui/switch";
import { PlusIcon } from "src/components/icons/plus-icon";
import { InfoIcon } from "src/components/icons/info-icon";
import moment from "moment";
import { DeleteIcon } from "src/components/icons/delete-icon";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import Button from "src/components/ui/button";
import AdminFormPage from "src/components/admins/admin-form";
import { bankTypeOptions } from "src/lib/constants";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: AccountDataType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {dataIndex === "is_partner" ? (
            <Select size="large" options={bankTypeOptions} />
          ) : (
            <Input />
          )}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const BankAccountPage: FC = () => {
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<AccountDataType[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { openModal } = useModalAction();

  const [isNew, setIsNew] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: AccountDataType) => record.key === editingKey;

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onCallback = (bSuccess: boolean) => {
    setIsNew(false);
    // setEditingKey(-1);

    bSuccess && getList(searchParams);
  };

  const { mutate: getList, isLoading } = useMutation(client.bankAccount.all, {
    onSuccess: (data) => {
      console.log(data);
      setTotal(data.total);
      if (data) {
        const newData = [...data.data];
        setDataSource(
          newData.map((item: any) => ({
            key: item.id,
            ...item,
          }))
        );
      }
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: updateOurPrices, isLoading: isUpdateLoading } = useMutation(
    client.bankAccount.update,
    {
      onSuccess: (data) => {
        setDataSource((prev: AccountDataType[]) => {
          return prev.map((item) => {
            return item.id === data.id ? { key: item.key, ...data } : item;
          });
        });
        setEditingKey("");
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    isMounted && getList(searchParams);
  }, [isMounted, searchParams]);

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({ ...searchParams, current_page: page });
  };

  const onAdd = () => {
    // setIsNew(true);
    setTimeout(() => {
      openModal("ADD_IBAN_ACCOUNT", {
        type: "save",
        callback: onCallback,
      });
    }, 100);
    // setEditingKey(-1);
  };

  const onDelete = (key: number) => {
    openModal("ACCOUNT_DELETE_CONFIRM_VIEW", {
      id: key,
      callback: onCallback,
    });
  };

  const edit = (record: Partial<AccountDataType> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key as string);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id: number, beneficiary_bank_address: string) => {
    try {
      const row = (await form.validateFields()) as AccountDataType;
      updateOurPrices({ ...row, id, beneficiary_bank_address });
      return;
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Name
        </p>
      ),
      dataIndex: "beneficiary_name",
      key: "beneficiary_name",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Bank name
        </p>
      ),
      dataIndex: "beneficiary_bank",
      key: "beneficiary_bank",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Type
        </p>
      ),
      dataIndex: "is_partner",
      key: "is_partner",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {value ? "Partner Bank" : "Other Bank"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          SWIFT
        </p>
      ),
      dataIndex: "swift",
      key: "swift",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Beneficiary IBAN
        </p>
      ),
      dataIndex: "iban",
      key: "iban",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Beneficiary Bank Address
        </p>
      ),
      dataIndex: "beneficiary_bank_address",
      key: "beneficiary_bank_address",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency
        </p>
      ),
      dataIndex: "asset_code",
      className: "w-auto",
      key: "asset_code",
      editable: false,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value?.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Correspondent Bank
        </p>
      ),
      dataIndex: "note",
      className: "w-auto",
      key: "note",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_: any, record: AccountDataType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="flex flex-row gap-2">
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={cancel}
            >
              <CloseIcon className="w-[20px] h-[20px] text-secondary" />
            </button>
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() =>
                save(Number(record.id), String(record.beneficiary_bank_address))
              }
            >
              <CheckIcon className="w-[20px] h-[20px] text-primary" />
            </button>
          </div>
        ) : (
          <div className="flex flex-row gap-2">
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => edit(record)}
            >
              <EditIcon className="w-[20px] h-[20px] text-primary" />
            </button>
            <button
              className="w-[30px] h-[30px] flex items-center justify-center"
              onClick={() => onDelete(record.key as number)}
            >
              <DeleteIcon className="w-[20px] h-[20px] text-primary" />
            </button>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AccountDataType) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: "",
        editing: isEditing(record),
      }),
    };
  });

  const mobileColumns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_: any, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Name
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.beneficiary_name}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Bank Name
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.beneficiary_bank}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Type
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.is_partner ? "Partner Bank" : "Other Bank"}
              </p>
            </div>

            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                SWIFT
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.swift}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Beneficiary IBAN
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.iban}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Beneficiary Bank Address
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.beneficiary_bank_address}
              </p>
            </div>

            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Currency
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.asset_code?.toUpperCase()}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Correspondent Bank
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.note}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium invisible">
                Edit
              </p>
              <a
                className="text-secondary underline hover:underline hover:text-primary text-[14px] font-manrope font-medium"
                // onClick={() => editOnMobile(record.key as number)}
              >
                Edit
              </a>
            </div>
          </div>
        );
      },
    },
  ];
  console.log(dataSource);

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <div className="w-full flex flex-row items-center justify-between">
          <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
            Bank account
          </p>
          <button
            className="flex flex-row items-center justify-between px-2 sm:px-4 py-2 gap-2 bg-white rounded-[3px] flex-shrink-0 hover:bg-[#E6E8EC]"
            onClick={onAdd}
          >
            <p className="font-manrope text-[14px] font-medium text-primary">
              Add new bank
            </p>
            <PlusIcon className="w-[18px] h-[18px] text-primary" />
          </button>
        </div>

        <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
          {isMobile ? (
            <Table
              loading={isLoading}
              showSorterTooltip={false}
              columns={mobileColumns}
              dataSource={dataSource}
              pagination={false}
            />
          ) : (
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                loading={isLoading}
                showSorterTooltip={false}
                columns={mergedColumns}
                dataSource={dataSource}
                pagination={{
                  total,
                  current: searchParams.current_page,
                  onChange: onPageChange,
                  position: ["bottomCenter"],
                  defaultPageSize: LIST_PAGE_SIZE,
                  showSizeChanger: false,
                }}
                rowClassName="editable-row"
              />
            </Form>
          )}
          {/* <Table
            loading={isLoading}
            showSorterTooltip={false}
            columns={mergedColumns}
            // columns={isMobile ? mobileColumns : columns}
            dataSource={dataSource}
            pagination={{
              total,
              current: searchParams.current_page,
              onChange: onPageChange,
              position: ["bottomCenter"],
              defaultPageSize: LIST_PAGE_SIZE,
              showSizeChanger: false,
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default BankAccountPage;
