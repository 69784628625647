export const ThreeDotsIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 8" {...props}>
      <path
        d="M3.75 0.5C1.825 0.5 0.25 2.075 0.25 4C0.25 5.925 1.825 7.5 3.75 7.5C5.675 7.5 7.25 5.925 7.25 4C7.25 2.075 5.675 0.5 3.75 0.5ZM28.25 0.5C26.325 0.5 24.75 2.075 24.75 4C24.75 5.925 26.325 7.5 28.25 7.5C30.175 7.5 31.75 5.925 31.75 4C31.75 2.075 30.175 0.5 28.25 0.5ZM16 0.5C14.075 0.5 12.5 2.075 12.5 4C12.5 5.925 14.075 7.5 16 7.5C17.925 7.5 19.5 5.925 19.5 4C19.5 2.075 17.925 0.5 16 0.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
