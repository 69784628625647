export function BankIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.36328 1.29102V12.2635C2.36328 13.1619 2.78495 14.0144 3.50911 14.5552L8.28494 18.1302C9.30244 18.891 10.7049 18.891 11.7224 18.1302L16.4983 14.5552C17.2224 14.0144 17.6441 13.1619 17.6441 12.2635V1.29102H2.36328Z"
        stroke="#7F8172"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M0.833984 1.29102H19.1673"
        stroke="#7F8172"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M6.33398 6.33398H13.6673"
        stroke="#7F8172"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.33398 10.916H13.6673"
        stroke="#7F8172"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
