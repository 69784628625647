import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { Fee, PaginationInput, User } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import {
  NotificationDataType,
  TransactionDataType,
} from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import ToggleSwitch from "src/components/ui/switch";
import { BackIcon } from "src/components/icons/back-icon";
import Button from "src/components/ui/button";
import { InvoiceIcon } from "src/components/icons/invoice-icon";
import { Dropdown, MenuProps, Space, Spin, Table } from "antd";
import { ThreeDotsIcon } from "src/components/icons/three-dots-icon";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PDFFileDownload from "../../components/ui/pdf-file-download";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <a>
        <div className="px-4 h-[40px] bg-[#FAFAFA] border border-[#E6E8EC] rounded-[8px] hover:bg-[#E6E8EC] flex items-center justify-center">
          <p className="font-manrope text-[14px] font-medium text-primary">
            Download
          </p>
        </div>
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a>
        <div className="px-4 h-[40px] bg-[#FAFAFA] border border-[#E6E8EC] rounded-[8px] hover:bg-[#E6E8EC] flex items-center justify-center">
          <p className="font-manrope text-[14px] font-medium text-primary">
            Print
          </p>
        </div>
      </a>
    ),
  },
];

const InvoicePage = ({
  data,
  callback,
}: {
  data: TransactionDataType;
  callback: any;
}) => {
  const navigate = useNavigate();
  const componentRef = useRef<HTMLDivElement>(null);

  const pdfExportComponent = useRef<PDFExport>(null);

  const onDownload = () => {
    if (!data) return;

    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const onPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "1") {
      onDownload();
    } else {
      onPrint();
    }
  };

  const onBack = () => {
    callback();
  };

  return (
    <div className="flex flex-col w-full">
      <div ref={componentRef} className="w-full h-full flex-col p-2 sm:p-4">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          Invoice
        </p>

        <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
          <div className="flex flex-row items-center justify-between w-full border-b-2 border-gray border-opacity-50 gap-2 pb-4">
            <div className="flex flex-row items-center gap-2">
              <Button
                variant="icon"
                className="w-[40px] h-[40px] bg-[#FAFAFA] border border-[#E6E8EC] rounded-[8px] hover:bg-[#E6E8EC]"
                onClick={onBack}
              >
                <BackIcon className="w-[20px] h-[20px] text-primary" />
              </Button>
              <InvoiceIcon className="w-[24px] h-[24px] text-primary ml-2" />
              <p className="text-primary font-manrope text-[18px] font-medium leading-[28px]">
                Invoice ID: {data ? String(data.key) : "-"}
              </p>
            </div>
            <div className="hidden md:flex flex-row items-center justify-between gap-2">
              <Button
                variant="text"
                className="px-4 h-[40px] bg-[#FAFAFA] border border-[#E6E8EC] rounded-[8px] hover:bg-[#E6E8EC]"
                onClick={onDownload}
              >
                <p className="font-manrope text-[14px] font-medium text-primary">
                  Download
                </p>
              </Button>
              <Button
                variant="text"
                className="px-4 h-[40px] bg-[#FAFAFA] border border-[#E6E8EC] rounded-[8px] hover:bg-[#E6E8EC]"
                onClick={onPrint}
              >
                <p className="font-manrope text-[14px] font-medium text-primary">
                  Print
                </p>
              </Button>
            </div>
            <Space direction="vertical" className="block md:hidden">
              <Dropdown menu={{ items, onClick }}>
                <Button
                  variant="icon"
                  className="w-[40px] h-[40px] bg-[#FAFAFA] border border-[#E6E8EC] rounded-[8px] hover:bg-[#E6E8EC]"
                >
                  <ThreeDotsIcon className="w-[20px] h-[20px] text-primary" />
                </Button>
              </Dropdown>
            </Space>
          </div>
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex flex-col items-center justify-between w-full">
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    User ID
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.user_id : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Date
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data
                      ? moment
                          .unix(data.created_at)
                          .format("YYYY-MM-DD HH:mm:ss")
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Type
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? capitalizeFirstLetter(data.type) : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    User Amount
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data
                      ? makeNumberWithFloatingDigits(data.total_amount, 2)
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Currency
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.asset_code.toUpperCase() : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Invoice Status
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? capitalizeFirstLetter(data.status) : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Comments
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.comment ?? "-" : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between w-full">
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Beneficiary Name
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.invoice_data!.beneficiary_name : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Bank Name
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.invoice_data!.beneficiary_bank : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    SWIFT
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.invoice_data!.swift : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    IBAN
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.invoice_data!.iban : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Reference
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.invoice_data!.reference : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    Address
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    {data ? data.invoice_data!.beneficiary_bank_address : "-"}
                  </p>
                </div>
              </div>
              <div className="hidden md:invisible sm:flex flex-row items-center justify-between w-full border-b border-gray border-opacity-50 py-3">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                    User ID
                  </p>
                  <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                    123456
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: "fixed", left: "-10000px" }}>
        <PDFExport ref={pdfExportComponent} paperSize="A4">
          {data && (
            <PDFFileDownload
              amount={data!.amount}
              data={[
                {
                  name: "Bank Name",
                  value: data!.invoice_data.beneficiary_bank,
                },
                { name: "SWIFT", value: data!.invoice_data.swift },
                {
                  name: "Beneficiary",
                  value: data!.invoice_data.beneficiary_name,
                },
                {
                  name: "Beneficiary IBAN",
                  value: data!.invoice_data.iban,
                },
                { name: "Reference", value: data!.invoice_data.reference },
                {
                  name: "Note",
                  value: data!.invoice_data.note,
                },
              ]}
              currency={data!.asset_code}
            />
          )}
        </PDFExport>
      </div>
    </div>
  );
};

export default InvoicePage;
