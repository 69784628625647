import { FC, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import client from "../../api";
import { LimitSettingsUpdate } from "../../api/types";
import { getErrorMessage } from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Col, Row } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { PaginationInput, User } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import { UserDataType } from "src/lib/constants/table-type";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import DepositListPage from "src/components/transactions/deposit";
import WithdrawalListPage from "src/components/transactions/withdrawal";
import TransferListPage from "src/components/transactions/transfer";
import GeneralLimitationsPage from "src/components/limitations/user/general";
import DailyLimitationsPage from "src/components/limitations/user/daily";
import { useLocation } from "react-router-dom";
import routes from "src/config/routes";
import ToggleSwitch from "../ui/switch";

type UserLimitationsPageProps = {
  user: User;
};

const UserLimitationsPage: FC<UserLimitationsPageProps> = ({ user }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [activeTab, setActiveTab] = useState(0);
  const [enabled, setEnabled] = useState(false);
  const [on, setOn] = useState(false);
  const [singleLimitsEnabled, setSingleLimitsEnabled] = useState(false);
  const [dailyLimitsEnabled, setDailyLimitsEnabled] = useState(false);
  const { pathname } = useLocation();

  const isBuisness = pathname === routes.usersBusiness;

  const isDaily = activeTab === 1;

  console.log(user.is_business);

  const { mutate: getSettings } = useMutation(
    user.is_business
      ? client.users.getBuisnessLimitSettings
      : client.users.getIndividualLimitSettings,
    {
      onSuccess: (data) => {
        const { single_limits_enabled, daily_limits_enabled } = data;
        setSingleLimitsEnabled(single_limits_enabled === 1);
        setDailyLimitsEnabled(daily_limits_enabled === 1);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateSettings, isLoading: updateLoading } = useMutation(
    user.is_business
      ? client.users.updateBuisnessLimitSettings
      : client.users.updateIndividualLimitSettings,
    {
      onSuccess: (data) => {
        const { single_limits_enabled, daily_limits_enabled } = data;
        setSingleLimitsEnabled(single_limits_enabled === 1);
        setDailyLimitsEnabled(daily_limits_enabled === 1);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  useEffect(() => {
    getSettings(user.id);
  }, [isBuisness, activeTab, getSettings, user.id]);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const tabLists = ["Single", "Daily"];

  const makeTabList = () => {
    const tabListsUI = tabLists.map((item, index) => (
      <Tab
        key={`tabs_${index}`}
        className={({ selected }) => {
          return classNames(
            "h-full font-medium text-[14px] md:text-[16px] relative px-2 py-2 md:px-8 md:py-4 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-brand before:transition-all before:duration-300 before:ease-in-out",
            "ring-transparent ring-opacity-60 ring-offset-0 ring-offset-transparent focus:outline-none focus:ring-0",
            selected
              ? "text-primary bg-white rounded-t-[8px]"
              : "text-secondary bg-transparent"
          );
        }}
      >
        {item}
      </Tab>
    ));
    return (
      <Tab.List className="flex flex-row items-center w-full h-[58px] md:h-[56px]">
        {tabListsUI}
      </Tab.List>
    );
  };

  const { mutate: exportLimitations } = useMutation(
    isDaily ? client.dailyLimits.export : client.limitations.export,
    {
      onSuccess: (data) => {
        console.log(data);
        setAlertText("Export success");
        setIsAlertOpened(true);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        exportLimitations();
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleSwitch = (type: string, value: boolean) => {
    const params: LimitSettingsUpdate = {
      single_limits_enabled: (!isDaily ? value : singleLimitsEnabled) ? 1 : 0,
      daily_limits_enabled: (isDaily ? value : dailyLimitsEnabled) ? 1 : 0,
    };
    updateSettings({ input: params, userId: user.id });
  };

  return (
    <div className="w-full h-full bg-white">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <Tab.Group onChange={setActiveTab}>
          <Row align="middle">
            <Col span={3}>{makeTabList()}</Col>
            <Col span={21}>
              <Row justify="end">
                <Col>
                  <ToggleSwitch
                    type="settings"
                    value={isDaily ? dailyLimitsEnabled : singleLimitsEnabled}
                    onValueChanged={handleSwitch}
                    disabled={updateLoading}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Tab.Panels>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <GeneralLimitationsPage id={user.id} user={user} />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <DailyLimitationsPage id={user.id} user={user} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default UserLimitationsPage;
