import { FC, useState } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { IOurPriceDataType } from "src/lib/constants/table-type";
import OurPricesTable from "./our-prices-table";

interface IBusinessTabs {
  lang: string;
  dataSource: IOurPriceDataType[];
  setDataSource: React.Dispatch<React.SetStateAction<IOurPriceDataType[]>>;
  isLoading: boolean;
  isWithdrawal?: boolean;
  isKwickpay?: boolean;
}

const tabLists = ["Private", "Business"];

const BusinessTabs: FC<IBusinessTabs> = ({
  lang,
  isWithdrawal,
  isKwickpay,
  isLoading,
  dataSource,
  setDataSource,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const makeTabList = () => {
    const tabListsUI = tabLists.map((item, index) => (
      <Tab
        key={`tab_list_${index}`}
        className={({ selected }) =>
          classNames(
            "relative pb-1.5 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-primary before:transition-all before:duration-300 before:ease-in-out hover:text-primary",
            "ring-transparent ring-opacity-60 ring-offset-0 ring-offset-transparent focus:outline-none focus:ring-0",
            {
              "font-semibold text-primary before:w-full": selected,
              "text-secondary before:w-0 dark:text-light-800": !selected,
            }
          )
        }
      >
        <p className="text-[14px] sm:text-[16px]">{item}</p>
      </Tab>
    ));
    return (
      <Tab.List className="px-2 sm:px-8 mt-2 sm:mt-4 font-manrope font-medium relative space-x-4 sm:space-x-8 z-10 flex items-center">
        {tabListsUI}
      </Tab.List>
    );
  };

  return (
    <div className="flex flex-col w-full bg-white rounded-[12px] p-4">
      <Tab.Group
        selectedIndex={selectedTabIndex}
        onChange={(tabIdx: number) => setSelectedTabIndex(tabIdx)}
      >
        {makeTabList()}
        <Tab.Panels>
          <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
            <OurPricesTable
              lang={lang}
              isWithdrawal={isWithdrawal}
              isKwickpay={isKwickpay}
              dataSource={dataSource}
              setDataSource={setDataSource}
              isLoading={isLoading}
            />
          </Tab.Panel>
          <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
            <OurPricesTable
              lang={lang}
              isWithdrawal={isWithdrawal}
              isKwickpay={isKwickpay}
              dataSource={dataSource}
              setDataSource={setDataSource}
              isLoading={isLoading}
              isBusiness
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default BusinessTabs;
