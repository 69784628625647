import {
    FC,
    useContext,
    useEffect,
    useState,
    ChangeEvent,
    useRef,
} from "react";

const FastTransferPage: FC = () => {

    return (
        <div className="w-full h-full bg-gray">
            <div className="w-full h-full flex-col p-2 sm:p-4">
                <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px] mb-4">
                    Fast international money transfers
                </p>

            </div>
        </div>
    );
};

export default FastTransferPage;
