export const AdminsIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3333 5.16414C12.3333 7.37203 10.5426 9.16153 8.33329 9.16153C6.12396 9.16153 4.33329 7.37203 4.33329 5.16414C4.33329 2.95625 6.12396 1.16675 8.33329 1.16675C10.5426 1.16675 12.3333 2.95625 12.3333 5.16414Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 19.8213H1.66663C1.66663 18.8745 1.66663 17.9739 1.66663 17.1583C1.66663 14.9491 3.45749 13.1589 5.66663 13.1589H11C13.2091 13.1589 15 14.9491 15 17.1583C15 17.9739 15 18.8745 15 19.8213Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1704 6.09985L16.1246 6.33276L15.9491 7.18501C15.6686 7.29214 15.4142 7.44405 15.1843 7.6242L14.3267 7.33745L14.0963 7.26603L13.9768 7.47176L13.3864 8.45884L13.267 8.6646L13.4419 8.81756L14.1054 9.38198C14.0814 9.52962 14.0504 9.67565 14.0504 9.83075C14.0504 9.98584 14.0814 10.1319 14.1054 10.2795L13.4419 10.844L13.267 10.9969L13.3864 11.2027L13.9768 12.1898L14.0963 12.396L14.3267 12.3241L15.1843 12.0373C15.4142 12.2175 15.6686 12.3694 15.9491 12.4765L16.1246 13.3287L16.1704 13.5617H17.8296L17.876 13.3287L18.051 12.4765C18.3315 12.3694 18.5859 12.2175 18.8158 12.0373L19.6734 12.3241L19.9038 12.396L20.0238 12.1898L20.6136 11.2027L20.7336 10.9969L20.5582 10.844L19.8947 10.2795C19.9192 10.1319 19.9496 9.98584 19.9496 9.83075C19.9496 9.67565 19.9192 9.52962 19.8947 9.38198L20.5582 8.81756L20.7336 8.6646L20.6136 8.45884L20.0238 7.47176L19.9038 7.26603L19.6734 7.33745L18.8158 7.6242C18.5859 7.44405 18.3315 7.29214 18.051 7.18501L17.876 6.33276L17.8296 6.09985H16.1704Z"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0668 9.83075C18.0668 10.4192 17.5889 10.8967 17.0001 10.8967C16.4113 10.8967 15.9334 10.4192 15.9334 9.83075C15.9334 9.24233 16.4113 8.76479 17.0001 8.76479C17.5889 8.76479 18.0668 9.24233 18.0668 9.83075Z"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
