import { FC, useState } from "react";
import { User } from "src/api/types";
import PaymentMethodCards from './payment-method-cards';
import PaymentMethodIbans from './payment-method-ibans';

type TTabs = "card" | "iban";

const tabs: { code: TTabs; name: string }[] = [
  {
    code: "card",
    name: "Card",
  },
  {
    code: "iban",
    name: "IBAN",
  },
];

type UserPaymentMethodsPageProps = {
  user: User;
};

const UserPaymentMethodsPage: FC<UserPaymentMethodsPageProps> = ({ user }) => {
  const [currentTab, setCurrentTab] = useState<TTabs>("card");

  return (
    <div className="bg-white rounded-[12px] w-full">
      <div className="p-4 relative">
        <div className="mt-[20px]">
          <div className="flex gap-4">
            {tabs.map((item) => (
              <button
                key={item.code}
                className={
                  item.code === currentTab
                    ? "pl-1 pr-1 relative text-primary after:absolute after:w-full after:bottom-[-5px] after:bg-[#232800] after:h-[3px] after:left-0 after:transition-opacity transition-colors"
                    : "pl-1 pr-1 relative text-secondary transition-colors hover:text-primary"
                }
                onClick={() => setCurrentTab(item.code)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        <div className="mt-[32px]">
          {currentTab === 'card' && <PaymentMethodCards user={user} />}
          {currentTab === 'iban' && <PaymentMethodIbans user={user} />}
        </div>
      </div>
    </div>
  );
};

export default UserPaymentMethodsPage;
