export const LimitationsIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 22 21" {...props}>
      <path
        d="M4.33329 3.83341L17.6666 17.1667M11 19.8334C5.8453 19.8334 1.66663 15.6547 1.66663 10.5001C1.66663 5.34542 5.8453 1.16675 11 1.16675C16.1546 1.16675 20.3333 5.34542 20.3333 10.5001C20.3333 15.6547 16.1546 19.8334 11 19.8334Z"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </svg>
  );
};
