export const ArrowUpIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
        <path d="M3.33268 10.8333L7.99935 6.16659L12.666 10.8333" stroke="#232800" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    );
};




