import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, InputNumber, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  SUCCESS_MODAL_TYPE,
} from "src/lib/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAuth from "src/hooks/use-auth";
import Password from "../ui/password";
import Button from "../ui/button";

const schema = yup
  .object({
    cur_password: yup
      .string()
      .required("This field is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be at least 8 characters long, include 1 uppercase letter, and 1 number"
      ),
    old_password: yup
      .string()
      .required("This field is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be at least 8 characters long, include 1 uppercase letter, and 1 number"
      )
      .oneOf([yup.ref("cur_password")], "Passwords do not match"),

    password: yup
      .string()
      .required("This field is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be at least 8 characters long, include 1 uppercase letter, and 1 number"
      ),
    password_confirmation: yup
      .string()
      .required("This field is required.")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const ChangePasswordPage: FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal, closeModal } = useModalAction();

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { getCurrrentUser, setCurrentUser } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      cur_password: getCurrrentUser().password ?? "",
      old_password: "",
    },
  });

  const newPassword = watch("password");

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const { mutate: changePassword, isLoading } = useMutation(
    client.auth.changePassword,
    {
      onSuccess: (data) => {
        console.log(data);

        setCurrentUser(getCurrrentUser(), newPassword);

        openModal("ACTION_SUCCESS", {
          type: SUCCESS_MODAL_TYPE.ChangePassword,
        });
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onSubmit = (data: FormData) => {
    changePassword(data);
  };

  return (
    <div className="bg-white rounded-r-[12px] rounded-bl-[12px] w-full relative">
      <form
        className="flex flex-col w-full md:w-[364px] bg-white rounded-[12px] p-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col w-full">
          <Password
            label="Old password"
            className="w-full"
            {...register("old_password")}
            error={
              errors.old_password && (errors.old_password.message as string)
            }
          />
          <Password
            label="New password"
            className="w-full mt-4"
            {...register("password")}
            error={errors.password && (errors.password.message as string)}
          />
          <Password
            label="Confirm password"
            className="w-full mt-4"
            {...register("password_confirmation")}
            error={
              errors.password_confirmation &&
              (errors.password_confirmation.message as string)
            }
          />
          <Button
            variant="validate"
            type="submit"
            className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] mt-4"
            disabled={!isValid || isLoading}
            isLoading={isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordPage;
