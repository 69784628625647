import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, InputNumber, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { Fee, PaginationInput, User } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import { NotificationDataType } from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import ToggleSwitch from "src/components/ui/switch";

const NotificationManagementPage: FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<NotificationDataType[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [editingKey, setEditingKey] = useState(-1);
  const [editingData, setEditingData] = useState<NotificationDataType | null>(
    null
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const { mutate: getNotifications, isLoading } = useMutation(
    client.notification.all,
    {
      onSuccess: (data) => {
        console.log(data);
        setDataSource(
          data.map((item) => ({
            key: item.id,
            ...item,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateNotification, isLoading: isLoadingUpdate } =
    useMutation(client.notification.update, {
      onSuccess: (data) => {
        console.log(data);
        if (editingData) {
          const newData = [...dataSource];
          const index = newData.findIndex(
            (item) => editingData.key == item.key
          );
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...editingData!,
            });
            setDataSource(newData);
          } else {
            newData.push(editingData!);
            setDataSource(newData);
          }
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    });

  useEffect(() => {
    isMounted && getNotifications();
  }, [isMounted]);

  const onValueChanged = (type: string, value: boolean) => {
    console.log(type, value);
    const items = type.split("_");
    const paymentType = items[0];

    let selData = dataSource.filter((item) => item.key == Number(items[1]))[0];
    selData = {
      ...selData,
      ...(paymentType === "sms"
        ? { sms_enabled: value }
        : { email_enabled: value }),
    };

    setEditingData(selData);

    setTimeout(() => updateNotification(selData), 100);
  };

  const columns: ColumnsType<NotificationDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Notifications
        </p>
      ),
      dataIndex: "name",
      key: "name",
      className: "w-auto sm:w-[45%]",
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          SMS
        </p>
      ),
      dataIndex: "sms_enabled",
      key: "sms_enabled",
      className: "w-auto",
      render: (_, record: { key: React.Key; sms_enabled: boolean }) => (
        <div className="flex flex-row gap-2 items-center">
          <ToggleSwitch
            type={`sms_${record.key}`}
            value={record.sms_enabled}
            onValueChanged={onValueChanged}
          />
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Email
        </p>
      ),
      dataIndex: "email_enabled",
      key: "email_enabled",
      className: "w-[60px]",
      render: (_, record: { key: React.Key; email_enabled: boolean }) => (
        <div className="flex flex-row gap-2 items-center">
          <ToggleSwitch
            type={`email_${record.key}`}
            value={record.email_enabled}
            onValueChanged={onValueChanged}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          Notification
        </p>

        <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
          <Table
            loading={isLoading || isLoadingUpdate}
            showSorterTooltip={false}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationManagementPage;
