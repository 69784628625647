import { forwardRef, useState, useEffect } from "react";
import classNames from "classnames";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  showInfo?: boolean;
  value: string;
  onChange: any;
  maxLength: number;
};

const InputCode = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      type = "text",
      className,
      inputClassName = "bg-transparent",
      showInfo = false,
      onChange,
      maxLength,
      value,
      ...props
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState(value ?? "");

    useEffect(() => {
      setCurrentValue(value ?? "");
    }, [value]);

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (val.length > maxLength) {
        return;
      }

      const re = /^[0-9\b]+$/;
      if (val === "" || re.test(val)) {
        setCurrentValue(val);
        onChange(val);
      }
    };

    return (
      <div className={className}>
        <label className="block text-16px font-manrope font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-[16px] font-manrope font-medium text-secondary dark:text-light/70">
              {label}
            </span>
          )}
          <input
            type={type}
            ref={ref}
            value={currentValue}
            onChange={onTextChange}
            {...props}
            className={classNames(
              "h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-[16px] font-manrope font-medium rounded-[12px] border border-border bg-transparent px-4 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:px-5",
              error
                ? "focus:border-[#F44336] !border-[#F44336] focus:ring-[#F44336]"
                : "focus:border-active focus:ring-active dark:focus:border-active dark:focus:ring-active",
              inputClassName
            )}
          />
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-xs text-secondary font-manrope text-[14px] font-medium"
          >
            Enter the 6-digit code from Google Authenticator
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

InputCode.displayName = "InputCode";
export default InputCode;
