export const BackCircleIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5998 7.20004L8.79981 12L13.5998 16.8M11.9998 23.2C5.81421 23.2 0.799804 18.1856 0.799805 12C0.799806 5.81446 5.81422 0.800048 11.9998 0.800049C18.1854 0.80005 23.1998 5.81446 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="square"
      />
    </svg>
  );
};
