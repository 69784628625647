import { FC, useContext, useEffect, useState } from "react";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import { BankCard, PaginationInput, User } from "src/api/types";
import {
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  NetworkDisconnectMsg,
} from "src/lib/constants";
import { capitalizeEachFirstLetter, getErrorMessage } from "src/lib/utils";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { BankCardDataType } from "src/lib/constants/table-type";

type UserPaymentMethodsPageProps = {
  user: User;
};

const UserPaymentMethodCards: FC<UserPaymentMethodsPageProps> = ({ user }) => {
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [bankCards, setBankCards] = useState<BankCard[]>([]);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<BankCardDataType[]>([]);

  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const { mutate: getCards, isLoading: isIndividualCardsLoading } = useMutation(
    user.is_business
      ? client.users.getBusinessCardNumbers
      : client.users.getIndividualCardNumbers,
    {
      onSuccess: (data) => {
        setTotal(data.total);
        setBankCards(data.data);
        setDataSource(
          data.data.map((item) => ({
            key: item.id,
            id: item.id,
            card_name: item.card_name,
            bank_name: item.bank_name,
            card_number_last4: item.card_number_last4,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, current_page: page });
  };

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    getCards({ id: user.id, params: searchParams });
  }, [isMounted, user]);

  useEffect(() => {
    // isMounted && getPaymentMethods({ id: user.id, params: searchParams });
  }, [isMounted, searchParams.current_page]);

  const columns: ColumnsType<BankCardDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Name
        </p>
      ),
      dataIndex: "bank_name",
      key: "bank_name",
      className: "w-auto",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {capitalizeEachFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Type
        </p>
      ),
      dataIndex: "card_name",
      key: "card_name",
      className: "w-auto",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {capitalizeEachFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Bank account number
        </p>
      ),
      dataIndex: "card_number_last4",
      key: "card_number_last4",
      className: "w-[148px]",
      render: (value: string) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {`**** **** **** ${value}`}
        </p>
      ),
    },
  ];

  return (
    <Table
      loading={isIndividualCardsLoading}
      showSorterTooltip={false}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        total,
        current: searchParams.current_page,
        onChange: onPageChange,
        position: ["bottomCenter"],
        defaultPageSize: LIST_PAGE_SIZE,
        showSizeChanger: false,
      }}
    />
  );
};

export default UserPaymentMethodCards;
