import { FC, useContext, useEffect, useState, MouseEvent } from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getCurrencyInfo, getErrorMessage } from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { DateRangePicker } from "rsuite";
import isBefore from "date-fns/isBefore";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import moment from "moment";
import { Spin } from "antd";
import { NetworkDisconnectMsg, APISomethingWrongMsg } from "src/lib/constants";
import { AssetBalance } from "src/api/types";
import Chart from "src/components/dashboard/chart";

const DashboardPage: FC = () => {
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [balances, setBalances] = useState<AssetBalance[]>([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [assetNames, setAssetNames] = useState<string[]>([]);
  const [assetBalances, setAssetBalances] = useState<number[]>([]);
  const [balanceTab, setBalanceTab] = useState<string | null>(null);

  const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);
  const [startDataDate, setStartDataDate] = useState<any>(null);

  const now = new Date();
  const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

  const [startDate, endDate] = dateRangeValue || [];

  const { mutate: getUserBalances, isLoading } = useMutation(
    client.dashboard.getBalances,
    {
      onSuccess: (data) => {
        console.log(data);

        setBalanceTab(data.balances[0].asset_code);

        setAssetNames(
          data.balances.map((item) => item.asset_code.toUpperCase())
        );
        setAssetBalances(data.balances.map((item) => item.balance_usd));

        setBalances(data.balances);
        setTotalBalance(data.total_balances_usd);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const handleBalanceTab = (assetCode: string) => {
    setBalanceTab(assetCode);
  };

  const { mutate: uploadStartDate } = useMutation(
    client.dashboard.getStartDate,
    {
      onSuccess: (data: any) => {
        if (data && data.start_day) setStartDataDate(data?.start_day);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    if (isMounted) {
      getUserBalances();
      uploadStartDate({});
    }
  }, [isMounted]);

  const { mutate: exportDashboard } = useMutation(client.dashboard.export, {
    onSuccess: (data) => {
      setAlertText("Export success");
      setIsAlertOpened(true);
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        exportDashboard({
          start_date: startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : undefined,
          end_date: endDate ? moment(endDate).format("yyyy-MM-DD") : undefined,
        });
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          Dashboard
        </p>{" "}
        <div className="mt-4 flex items-center justify-end w-full mb-4">
          <div className="flex gap-2">
            <DateRangePicker
              format="dd/MM/yyyy"
              size="lg"
              block
              placement="bottomEnd"
              style={{ width: "308px" }}
              value={dateRangeValue}
              onChange={setDateRangeValue}
              shouldDisableDate={(date) => {
                console.log(date);
                const dataSet = new Date(startDataDate);
                dataSet.setDate(dataSet.getDate() - 1);
                return isBefore(date, new Date(dataSet));
              }}
              defaultCalendarValue={[prevMonth, now]}
              ranges={[]}
            />
            <button
              type="button"
              onClick={() => exportBtn()}
              className="button button--type3 button--gap30"
            >
              Export Excel
              <span className="btn-icon btn-icon--fill">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.79961 1.8002L12.6996 5.4002M8.79961 1.8002L5.19961 5.4002M8.79961 1.8002L8.79961 13.2002M15.9996 8.4002V16.2002L1.59961 16.2002L1.59961 8.4002"
                    stroke="#232800"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="relative flex flex-col w-full items-center justify-between bg-white rounded-[12px] mt-2 sm:mt-4 p-4 sm:p-6 gap-4">
          {isLoading && (
            <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          )}
          <div className="flex flex-col w-full gap-4">
            <div className="flex flex-row gap-2 items-center">
              <p className="font-manrope text-[18px] font-bold text-primary leading-[16px]">
                Balance
              </p>
              <p className="font-manrope text-[14px] font-light text-secondary leading-[16px]">
                {`Total Balance: $${totalBalance}`}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {balances.map((item, key) => (
                <div
                  key={`asset-${key}`}
                  className="group w-full h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400"
                  onClick={() => handleBalanceTab(item.asset_code)}
                >
                  <div className="flex flex-row gap-2 items-center">
                    <div className="w-[24px] h-[24px]">
                      {getCurrencyInfo(item.asset_code.toUpperCase()).icon}
                    </div>
                    <p className="font-manrope text-[12px] font-medium text-primary leading-[16px]">
                      {item.asset_code.toUpperCase()}
                    </p>
                  </div>
                  <p className="font-manrope text-[14px] font-medium text-primary leading-[16px]">
                    {item.balance}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {!isLoading && (
            <Chart data={balances} currentBalanceItemName={balanceTab!} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
