export const FeeIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.66663 7.83341H8.99996M13 13.1667H14.3333M14.3333 7.16675L7.66663 13.8334M10.0686 1.55254L8.5632 3.05791C8.31618 3.30493 7.98115 3.4437 7.63181 3.4437H5.26077C4.53331 3.4437 3.94358 4.03343 3.94358 4.76089V7.13193C3.94358 7.48127 3.8048 7.81631 3.55778 8.06333L2.05242 9.56869C1.53803 10.0831 1.53803 10.9171 2.05242 11.4315L3.55778 12.9368C3.8048 13.1839 3.94358 13.5189 3.94358 13.8682V16.2393C3.94358 16.9667 4.53331 17.5565 5.26077 17.5565H7.63181C7.98115 17.5565 8.31618 17.6952 8.5632 17.9423L10.0686 19.4476C10.583 19.962 11.417 19.962 11.9314 19.4476L13.4367 17.9423C13.6837 17.6952 14.0188 17.5565 14.3681 17.5565H16.7391C17.4666 17.5565 18.0563 16.9667 18.0563 16.2393V13.8682C18.0563 13.5189 18.1951 13.1839 18.4421 12.9368L19.9475 11.4315C20.4619 10.9171 20.4619 10.0831 19.9475 9.56869L18.4421 8.06333C18.1951 7.81631 18.0563 7.48127 18.0563 7.13193V4.76089C18.0563 4.03343 17.4666 3.4437 16.7391 3.4437H14.3681C14.0188 3.4437 13.6837 3.30493 13.4367 3.05791L11.9314 1.55254C11.417 1.03815 10.583 1.03815 10.0686 1.55254Z"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </svg>
  );
};
