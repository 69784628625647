import { FC, useContext, useEffect, useState } from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  getErrorMessage,
  splitLetettLetter,
  getReset2faStatusStyle,
  getReset2faStatusName,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Table, Tooltip } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  reset2faFilterOptions,
} from "src/lib/constants";
import { PaginationInput } from "src/api/types";

import { IReset2FAItem } from "src/api/types";
import moment from "moment";
import "react-swipeable-list/dist/styles.css";
import SearchFilter from "src/components/search-filters/reset2fa-filter";

const Reset2FAPage: FC = () => {
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [filter, setFilter] = useState("user_id");
  const [searchText, setSearchText] = useState("");

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<IReset2FAItem[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { openModal } = useModalAction();

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onCallback = (bSuccess: boolean) => {
    bSuccess && getList(searchParams);
  };

  const { mutate: getList, isLoading } = useMutation(
    client.users.getReset2faList,
    {
      onSuccess: (data) => {
        console.log(data);
        setTotal(data.total);
        if (data) {
          const newData = [...data.data];
          setDataSource(
            newData.map((item: any) => ({
              key: item.id,
              ...item,
            }))
          );
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted) {
        getList({
          ...searchParams,
          search_value: searchText || undefined,
          search_field: searchText ? filter : undefined,
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [isMounted, searchParams.current_page, searchText]);

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({ ...searchParams, current_page: page });
  };

  const onConfirm = (key: number) => {
    const selData = dataSource.filter((item) => item.key === key)[0];

    openModal("RESET_2FA_ACCEPT_VIEW", {
      type: "2fa",
      subType: "accept",
      info: selData,
      callback: onCallback,
    });
  };
  const onReject = (key: number) => {
    const selData = dataSource.filter((item) => item.key === key)[0];

    openModal("RESET_2FA_ACCEPT_VIEW", {
      type: "2fa",
      subType: "reject",
      info: selData,
      callback: onCallback,
    });
  };

  const openViewPhoto = (photo: any) => {
    openModal("PHOTO_2FA_VIEW", {
      type: "photo",
      info: photo,
      callback: onCallback,
    });
  };

  const columns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          User ID
        </p>
      ),
      dataIndex: "user_id",
      key: "user_id",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          E-mail
        </p>
      ),
      dataIndex: "email",
      key: "email",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {splitLetettLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Status
        </p>
      ),
      dataIndex: "status",
      key: "status",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center rounded-full">
          <p
            className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getReset2faStatusStyle(
              String(value)
            )}`}
          >
            {getReset2faStatusName(String(value))}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Data
        </p>
      ),
      dataIndex: "created_at",
      key: "created_at",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {moment.unix(Number(value)).utc(true).format("DD MMM YYYY H:mm z")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Documents
        </p>
      ),
      dataIndex: "selfie",
      key: "selfie",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            <button type="button" onClick={() => openViewPhoto(value)}>
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9662 10.4997C13.9662 12.2322 12.5662 13.6322 10.8337 13.6322C9.10117 13.6322 7.70117 12.2322 7.70117 10.4997C7.70117 8.76719 9.10117 7.36719 10.8337 7.36719C12.5662 7.36719 13.9662 8.76719 13.9662 10.4997Z"
                  stroke="#7F8172"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.8334 17.7371C13.9221 17.7371 16.8009 15.9171 18.8046 12.7671C19.5921 11.5334 19.5921 9.45961 18.8046 8.22586C16.8009 5.07586 13.9221 3.25586 10.8334 3.25586C7.74461 3.25586 4.86586 5.07586 2.86211 8.22586C2.07461 9.45961 2.07461 11.5334 2.86211 12.7671C4.86586 15.9171 7.74461 17.7371 10.8334 17.7371Z"
                  stroke="#7F8172"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Reason
        </p>
      ),
      dataIndex: "message",
      key: "message",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center cursor-pointer">
          <Tooltip
            title={value}
            placement="bottom"
            color="white"
            overlayInnerStyle={{
              color: "#232800",
            }}
          >
            <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
              {value}
            </p>
          </Tooltip>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-[100px]",
      render: (_: any, record: { key: React.Key; status: string }) => {
        const disabled = String(record.status) !== "1";

        return (
          <div className="flex flex-row gap-4">
            <a
              className={` text-[14px] font-manrope font-medium  ${
                disabled
                  ? "cursor-not-allowed text-[#BCBEAD] hover:text-[#BCBEAD]"
                  : "text-primary"
              }`}
              onClick={() => (disabled ? "" : onConfirm(record.key as number))}
            >
              Accept
            </a>
            <a
              className={` text-[14px] font-manrope font-medium ${
                disabled
                  ? "cursor-not-allowed text-[#BCBEAD]  hover:text-[#BCBEAD]"
                  : "text-error"
              }`}
              onClick={() => (disabled ? "" : onConfirm(record.key as number))}
            >
              Reject
            </a>
          </div>
        );
      },
    },
  ];

  const mobileColumns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_: any, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                User ID
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.user_id}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                E-mail
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.email}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Status
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.status ? "Partner Bank" : "Other Bank"}
              </p>
            </div>

            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Data
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {moment
                  .unix(Number(selData.created_at))
                  .utc(true)
                  .format("DD MMM YYYY H:mm z")}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Documents
              </p>
              <button
                type="button"
                onClick={() => openViewPhoto(selData.selfie)}
                className="text-primary text-[14px] font-manrope font-medium"
              >
                {selData.selfie}
              </button>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Reason
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.message}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium invisible">
                Action
              </p>
              <div className="flex flex-row gap-4">
                <a
                  className="text-primary text-[14px] font-manrope font-medium"
                  onClick={() => onConfirm(record.key as number)}
                >
                  Accept
                </a>
                <a
                  className="text-error text-[14px] font-manrope font-medium"
                  onClick={() => onReject(record.key as number)}
                >
                  Reject
                </a>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <div className="w-full flex flex-row items-center justify-between">
          <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
            2FA reset request
          </p>
        </div>
        <div className="mt-4 flex items-center justify-between w-full mb-4">
          <SearchFilter
            options={reset2faFilterOptions}
            value={filter}
            onChange={setFilter}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>
        <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
          <Table
            loading={isLoading}
            showSorterTooltip={false}
            columns={isMobile ? mobileColumns : columns}
            dataSource={dataSource}
            pagination={{
              total,
              current: searchParams.current_page,
              onChange: onPageChange,
              position: ["bottomCenter"],
              defaultPageSize: LIST_PAGE_SIZE,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Reset2FAPage;
