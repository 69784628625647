export function DeleteIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.3249 9.46875C19.3249 9.46875 18.7819 16.2037 18.4669 19.0407C18.3169 20.3957 17.4799 21.1898 16.1089 21.2148C13.4999 21.2618 10.8879 21.2648 8.27991 21.2098C6.96091 21.1828 6.13791 20.3788 5.99091 19.0478C5.67391 16.1858 5.13391 9.46875 5.13391 9.46875"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7082 6.24023H3.75021"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4406 6.23998C16.6556 6.23998 15.9796 5.68498 15.8256 4.91598L15.5826 3.69998C15.4326 3.13898 14.9246 2.75098 14.3456 2.75098H10.1126C9.53361 2.75098 9.02561 3.13898 8.87561 3.69998L8.63261 4.91598C8.47861 5.68498 7.80261 6.23998 7.01761 6.23998"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
