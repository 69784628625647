import { FC, useContext, useEffect, useState } from "react";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import { BankCard, PaginationInput, User } from "src/api/types";
import {
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  NetworkDisconnectMsg,
} from "src/lib/constants";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  convertPaymentSystem,
  getCurrencyInfo,
  getErrorMessage,
  getTransactionStatusStyle,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  BalanceDataType,
  BankCardDataType,
  TransactionDataType,
} from "src/lib/constants/table-type";
import { useModalAction } from "../modal-views/context";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";

const WithdrawalListPage = ({
  searchText,
  onSelectedInvoiceData,
  onSelectedUser,
  searchField,
  startDate,
  endDate,
  isBuisiness,
}: {
  searchText: string;
  onSelectedInvoiceData: any;
  onSelectedUser: any;
  searchField: string;
  startDate?: Date | null;
  endDate?: Date | null;
  isBuisiness?: boolean;
}) => {
  const navigate = useNavigate();

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();

  const [dataSource, setDataSource] = useState<TransactionDataType[]>([]);
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    type: "withdraw",
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const [total, setTotal] = useState(0);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted) {
        console.log("called");
        getList({
          ...searchParams,
          search_value: searchText.length > 0 ? searchText : undefined,
          search_field: searchText.length > 0 ? searchField : undefined,
          start_date: startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : undefined,
          end_date: endDate ? moment(endDate).format("yyyy-MM-DD") : undefined,
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [
    isMounted,
    searchParams.current_page,
    searchText,
    searchField,
    startDate,
    endDate,
    isBuisiness,
  ]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      current_page: 1,
    });
  }, [searchText]);

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({
      ...searchParams,
      current_page: page,
    });
  };

  const onSelectUser = (userID: number) => {
    navigate(`/${isBuisiness ? "users-business" : "users"}/${String(userID)}`, {
      replace: true,
    });
  };

  const { mutate: getList, isLoading } = useMutation(
    isBuisiness
      ? client.transactions.buisinessWithdrawal
      : client.transactions.individualWithdrawal,
    {
      onSuccess: (data) => {
        console.log(data);
        setTotal(data.total);

        setDataSource(
          data.data.map((item) => ({
            key: item.id,
            user_id: item.user_id,
            asset_id: item.asset_id,
            amount: item.amount,
            total_amount: item.total_amount,
            amount_fee: item.amount_fee,
            payment_system: item.payment_system,
            status: item.status,
            type: item.type,
            description: item.description,
            comment: item.comment,
            to: item.to,
            withdraw_payment_system: item.withdraw_payment_system,
            refund_id: item.refund_id,
            created_at: item.created_at,
            updated_at: item.updated_at,
            indicated_amount: item.indicated_amount,
            asset_code: item.asset.code,
            invoice: item.requisites ? true : false,
            invoice_data: item.requisites,
            full_name: item.full_name,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onViewInvoice = (key: number) => {
    const filterInvoices = dataSource.filter((item) => item.key === key);
    if (filterInvoices.length === 0) {
      return;
    }

    const selInvoice = filterInvoices[0];
    onSelectedInvoiceData(selInvoice);

    /*
    setInvoiceFromPage(routes.transactions);
    selInvoice.invoice_data &&
      navigate(routes.invoice, { state: { info: selInvoice } });
    */
  };

  const changeName = (name: string) => {
    return name === "cartubank" ? "Card" : name;
  };

  const columns: ColumnsType<TransactionDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          ID
        </p>
      ),
      dataIndex: "key",
      className: "w-auto",
      key: "key",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          User ID
        </p>
      ),
      dataIndex: "user_id",
      key: "user_id",
      className: "w-auto",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <a
            className="font-manrope text-secondary text-[14px] font-medium underline hover:text-primary hover:underline"
            onClick={() => onSelectUser(value)}
          >
            {value}
          </a>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency
        </p>
      ),
      dataIndex: "asset_code",
      className: "w-auto",
      key: "asset_code",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Full name
        </p>
      ),
      dataIndex: "full_name",
      className: "w-auto",
      key: "full_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Indicated Amount
        </p>
      ),
      dataIndex: "indicated_amount",
      key: "indicated_amount",
      className: "w-auto",
      sorter: (a, b) => a.indicated_amount - b.indicated_amount,
      render: (value: number) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Fee
        </p>
      ),
      dataIndex: "amount_fee",
      className: "w-auto",
      key: "amount_fee",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Amount
        </p>
      ),
      dataIndex: "total_amount",
      key: "total_amount",
      className: "w-auto",
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Payment Method
        </p>
      ),
      dataIndex: "payment_system",
      className: "w-auto",
      key: "payment_system",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {convertPaymentSystem(changeName(value))}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Invoice
        </p>
      ),
      dataIndex: "invoice",
      className: "w-auto",
      render: (
        _,
        record: { key: React.Key; invoice: boolean; invoice_data?: any }
      ) => (
        <div
          className={`${
            !record.invoice ? "invisible" : ""
          } flex flex-row gap-4`}
        >
          <a
            className="text-secondary underline hover:underline hover:text-primary text-[12px] font-manrope font-medium"
            onClick={() => onViewInvoice(record.key as number)}
          >
            Invoice
          </a>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Status
        </p>
      ),
      dataIndex: "status",
      className: "w-auto",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center rounded-full">
          <p
            className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getTransactionStatusStyle(
              value
            )}`}
          >
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Created
        </p>
      ),
      dataIndex: "created_at",
      className: "w-auto",
      key: "created_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {moment.unix(value).utc(true).format("DD MMM YYYY H:mm z")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Updated
        </p>
      ),
      dataIndex: "updated_at",
      className: "w-auto",
      key: "updated_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {moment.unix(value).utc(true).format("DD MMM YYYY H:mm z")}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<TransactionDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-row gap-4 items-start justify-between">
            <div className="w-full flex flex-col items-start gap-2">
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  ID
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {selData ? String(selData.key) : "-"}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Asset
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium text-">
                  {selData.asset_code.toUpperCase()}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Full name
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium text-">
                  {selData.full_name}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Amount
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(selData.total_amount, 2)}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Fee
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(selData.amount_fee, 2)}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Status
                </p>
                <p
                  className={`font-manrope text-[124x] font-medium px-2 rounded-full ${getTransactionStatusStyle(
                    selData.status
                  )}`}
                >
                  {capitalizeFirstLetter(selData.status)}
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Created
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {moment
                    .unix(selData.created_at)
                    .utc(true)
                    .format("DD MMM YYYY HH:mm z")}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Updated
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {moment
                    .unix(selData.updated_at)
                    .utc(true)
                    .format("DD MMM YYYY HH:mm z")}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  User ID
                </p>
                <a
                  className="font-manrope text-secondary text-[14px] font-medium underline hover:text-primary hover:underline  text-right"
                  onClick={() => onSelectUser(selData.user_id)}
                >
                  {selData.user_id}
                </a>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Indicated am.
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium ">
                  {makeNumberWithFloatingDigits(selData.indicated_amount, 2)}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Payment Method
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {convertPaymentSystem(
                    changeName(String(selData.payment_system))
                  )}
                </p>
              </div>
              <div
                className={`${
                  !selData.invoice ? "hidden" : ""
                } w-full flex flex-row items-center justify-between gap-2`}
              >
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Invoice
                </p>

                <a
                  className="text-primary underline hover:underline hover:text-secondary text-[14px] font-manrope font-medium"
                  onClick={() => onViewInvoice(selData.key as number)}
                >
                  Invoice
                </a>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white rounded-[12px] w-full">
      <div className="p-4 relative">
        <Table
          loading={isLoading}
          showSorterTooltip={false}
          columns={isMobile ? mobileColumns : columns}
          dataSource={dataSource}
          pagination={{
            total,
            current: searchParams.current_page,
            onChange: onPageChange,
            position: ["bottomCenter"],
            defaultPageSize: LIST_PAGE_SIZE,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
};

export default WithdrawalListPage;
