export const API_ENDPOINTS = {
  USERS_LOGIN: "/admin/login",
  CHANGE_PASSWORD: "/admin/password/change",
  USERS_LOGOUT: "/admin/logout",
  GET_USERS_BALANCES: "/admin/user_balances",
  GET_DASHBORAD_EXPORT: "/admin/export_user_balance_history",
  GET_HISTORY_DAY: "/admin/history_start_day",
  GET_USERS: "/admin/users",

  GET_INDIVIDUAL_USERS: "/admin/individual/users",
  GET_BUISINESS_USERS: "/admin/business/users",
  GET_USER: "/admin/user/",
  BLOCK_USER: "/blocked",
  UPDATE_VIP: "/vip",
  GET_USER_TRANSACTIONS: "/transactions",
  GET_USER_TRANSFERS: "/transfers",
  GET_USER_BANKCARDS: "/bank_cards",
  GET_USER_BALANCES: "/balances",
  GET_BANK_ACCOUNTS: "/admin/platform_bank_account",
  GET_INSIVIDUAL_DEPOSIT_TRANSACTIONS: "/admin/individual/payment/deposits",
  GET_BUISINESS_DEPOSIT_TRANSACTIONS: "/admin/business/payment/deposits",
  GET_INDIVIDUAL_WITHDRAWAL_TRANSACTIONS:
    "/admin/individual/payment/withdrawals",
  GET_BUISINESS_WITHDRAWAL_TRANSACTIONS: "/admin/business/payment/withdrawals",
  GET_TRANSFERS: "/admin/payment/transfers",
  GET_INDIVIDUAL_KWICKPAY_TRANSACTIONS:
    "/admin/individual/payment/kwikpay_transactions",
  GET_BUISINESS_KWICKPAY_TRANSACTIONS:
    "/admin/business/payment/kwikpay_transactions",
  GOOGLE_2FA_GENERATE_KEY: "/admin/2fa/generate_secret_key",
  GOOGLE_2FA_ENABLE: "/admin/2fa/enable",
  GOOGLE_2FA_DISABLE: "/admin/2fa/disable",
  GET_NOTIFICATIONS: "/admin/notifications",
  UPDATE_NOTIFICATION: "/admin/notifications/update",
  GET_ADMINS: "/admin/admins",
  GET_ADMIN: "/admin/admin",
  GET_PERMISSIONS: "/admin/permissions",
  GET_CURRENCIES: "/admin/assets",
  GET_LIMITATIONS: "/admin/limits",
  GET_DAILY_LIMITS: "/admin/daily_limits",
  GET_SINGLE_LIMITATIONS: "/admin/limitations/individual/single",
  GET_DAILY_LIMITATIONS: "/admin/limitations/individual/daily",
  GET_SINGLE_EXPORT: "/admin/limitations/individual/export-single",
  GET_DAILY_EXPORT: "/admin/limitations/individual/export-daily",
  GET_SINGLE_BUSINESS_EXPORT: "/admin/limitations/business/export-single",
  GET_DAILY_BUSINESS_EXPORT: "/admin/limitations/business/export-daily",
  GET_SINGLE_BUSINESS_LIMITATIONS: "/admin/limitations/business/single",
  GET_DAILY_BUSINESS_LIMITATIONS: "/admin/limitations/business/daily",
  GET_FEES: "/admin/fees",
  GET_CHANELS: "/admin/channels",
  GET_FEES_INDIVIDUAL: "/admin/fees-management/individual/ranges-percent",
  GET_FEES_INDIVIDUAL_PERCENT:
    "/admin/fees-management/individual/range/percent",
  GET_FEES_BUSINESS: "/admin/fees-management/business/ranges-percent",
  GET_FEES_BUSINESS_PERCENT: "/admin/fees-management/business/range/percent",
  GET_FIAT_LIST: "/admin/fiat-list",
  GET_FEES_INDIVIDUAL_EXPORT:
    "/admin/fees-management/individual/export-range-percent",
  GET_FEES_BUSINESS_EXPORT:
    "/admin/fees-management/business/export-range-percent",
  GET_INDIVIDUAL_PENDING_DEPOSIT: "/admin/individual/payment/deposits/pending",
  CONFIRM_INDIVIDUAL_PENDING_DEPOSIT:
    "/admin/individual/payment/deposit/complete",
  CANCEL_INDIVIDUAL_PENDING_DEPOSIT: "/admin/individual/payment/deposit/cancel",
  GET_BUSINESS_PENDING_DEPOSIT: "/admin/business/payment/deposits/pending",
  CONFIRM_BUSINESS_PENDING_DEPOSIT: "/admin/business/payment/deposit/complete",
  CANCEL_BUSINESS_PENDING_DEPOSIT: "/admin/business/payment/deposit/cancel",
  GET_UNIDENTIFIED_DEPOSITS: "/admin/bank_transfer/unknown_deposits",
  CONFIRM_UNIDENTIFIED_DEPOSIT: "/admin/bank_transfer/unknown_deposits/confirm",
  REJECT_UNIDENTIFIED_DEPOSIT: "/admin/bank_transfer/unknown_deposits/reject",
  FIND_USER: "/admin/bank_transfer/find_user",
  GET_INDIVIDUAL_PENDING_WITHDRAWAL:
    "/admin/individual/payment/withdrawals/pending",
  CONFIRM_INDIVIDUAL_PENDING_WITHDRAWAL:
    "/admin/individual/payment/withdrawal/complete",
  CANCEL_INDIVIDUAL_PENDING_WITHDRAWAL:
    "/admin/individual/payment/withdrawal/cancel",
  GET_BUSINESS_PENDING_WITHDRAWAL:
    "/admin/business/payment/withdrawals/pending",
  CONFIRM_BUSINESS_PENDING_WITHDRAWAL:
    "/admin/business/payment/withdrawal/complete",
  CANCEL_BUSINESS_PENDING_WITHDRAWAL:
    "/admin/business/payment/withdrawal/cancel",
  GET_INDIVIDUAL_USER: "/admin/individual/user",
  OUR_PRICES: "/admin/our-prices",
  GET_RESET2FA_LIST: "/admin/2fa/reset",
  GET_RESET2FA_REJECT: "/admin/2fa/reject",
  GET_EMAIL_HISTORY_LIST: "/admin/email-2fa-history",
  GET_SMS_HISTORY_LIST: "/admin/sms-2fa-history",
  PUT_RISK_LELVEL_INDIVIDUAL: (id: number) =>
    `admin/individual/user/${id}/risk_level`,
  PUT_RISK_LELVEL_BUISINESS: (id: number) =>
    `admin/business/user/${id}/risk_level`,
  GET_USER_INDIVIDUAL_LIMITATIONS: (id: number) =>
    `/admin/individual/user/${id}/limitations`,
  GET_USER_DAILY_INDIVIDUAL_LIMITATIONS: (id: number) =>
    `/admin/individual/user/${id}/daily_limitations`,
  GET_USER_BUISNESS_LIMITATIONS: (id: number) =>
    `/admin/business/user/${id}/limitations`,
  GET_USER_DAILY_BUISNESS_LIMITATIONS: (id: number) =>
    `/admin/business/user/${id}/daily_limitations`,
  USER_INDIVIDUAL_LIMITATION_SETTINGS: (id: number) =>
    `/admin/individual/user/${id}/limitation_settings`,
  USER_BUISNESS_LIMITATION_SETTINGS: (id: number) =>
    `/admin/business/user/${id}/limitation_settings`,
  UDPATE_USER_UML_STATUS: (id: number) =>
    `/admin/individual/user/${id}/aml_status`,
  UDPATE_BUISINESS_UML_STATUS: (id: number) =>
    `/admin/business/user/${id}/aml_status`,
  UPDATE_INDIVIDUAL_USER_VIP: (id: number) =>
    `/admin/individual/user/${id}/vip`,
  UPDATE_BUISINESS_USER_VIP: (id: number) => `/admin/business/user/${id}/vip`,
  GET_INDIVIDUAL_BANK_CARDS: (id: number) =>
    `/admin/individual/user/${id}/bank_cards`,
  GET_INDIVIDUAL_IBAN_ACCOUNTS: (id: number) =>
    `/admin/individual/user/${id}/iban_accounts`,
  GET_BUISINESS_BANK_CARDS: (id: number) =>
    `/admin/business/user/${id}/bank_cards`,
  GET_BUISINESS_IBAN_ACCOUNTS: (id: number) =>
    `/admin/business/user/${id}/iban_accounts`,
  GET_INDIVIDUAL_USET_SETTINGS: (id: number) =>
    `/admin/individual/user/${id}/user_settings`,
  GET_BUISINESS_USET_SETTINGS: (id: number) =>
    `/admin/business/user/${id}/user_settings`,
  GET_INDIVIDUAL_USER_BALANCES: (id: number) =>
    `/admin/individual/user/${id}/balances`,
  GET_BUISINESS_USER_BALANCES: (id: number) =>
    `/admin/business/user/${id}/balances`,
  GET_INDIVIDUAL_USER_TRANSACTIONS: (id: number) =>
    `/admin/individual/user/${id}/transactions`,
  GET_BUISINESS_USER_TRANSACTIONS: (id: number) =>
    `/admin/business/user/${id}/transactions`,
  GET_USERS_BLCKED: (type: string) => `/admin/${type}/user`,
};
