import { FC, useContext, useEffect, useState } from "react";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import { BankCard, PaginationInput, User } from "src/api/types";
import {
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  NetworkDisconnectMsg,
} from "src/lib/constants";
import {
  capitalizeEachFirstLetter,
  getCurrencyInfo,
  getErrorMessage,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  BalanceDataType,
  BankCardDataType,
} from "src/lib/constants/table-type";

type UserBalancesPageProps = {
  user: User;
};

const UserBalancesPage: FC<UserBalancesPageProps> = ({ user }) => {
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [dataSource, setDataSource] = useState<BalanceDataType[]>([]);

  const { mutate: getBalances, isLoading } = useMutation(
    user.is_business
      ? client.users.getBusinessBalances
      : client.users.getIndividualBalances,
    {
      onSuccess: (data) => {
        console.log(data);
        setDataSource(
          data.wallets.map((item) => ({
            key: item.id,
            wallet_id: item.id,
            currency: item.asset.code,
            amount: item.total,
            currency_id: item.asset.id,
            name: item.asset.name,
            img_path: item.asset.img_path,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    isMounted && getBalances(user.id);
  }, [isMounted]);

  const columns: ColumnsType<BalanceDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency
        </p>
      ),
      dataIndex: "currency",
      className: "w-auto",
      key: "currency",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <div className="w-[24px] h-[24px]">{getCurrencyInfo(value).icon}</div>
          <div className="flex flex-col flex-shrink">
            <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
              {value.toUpperCase()}
            </p>
            <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
              {getCurrencyInfo(value).descr}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Amount
        </p>
      ),
      dataIndex: "amount",
      key: "amount",
      className: "w-[100px]",
      render: (value: number) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
  ];

  return (
    <div className="bg-white rounded-[12px] w-full">
      <div className="p-4 relative">
        <Table
          loading={isLoading}
          showSorterTooltip={false}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default UserBalancesPage;
