import { FC, useContext, useEffect, useState } from "react";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import { BankCard, PaginationInput, User } from "src/api/types";
import {
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  NetworkDisconnectMsg,
} from "src/lib/constants";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  convertPaymentSystem,
  getCurrencyInfo,
  getErrorMessage,
  getTransactionStatusStyle,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  BalanceDataType,
  BankCardDataType,
  IKwickpayTransaction,
} from "src/lib/constants/table-type";
import { useModalAction } from "../modal-views/context";
import moment from "moment";
import routes from "src/config/routes";
import { useNavigate } from "react-router-dom";

type TransactionsKwickpayPageProps = {
  user: User;
  onSelectedInvoiceData: any;
};

const TransactionsKwickpayPage: FC<TransactionsKwickpayPageProps> = ({
  user,
  onSelectedInvoiceData,
}) => {
  const navigate = useNavigate();
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();

  const [dataSource, setDataSource] = useState<IKwickpayTransaction[]>([]);
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    search_field: "user_id",
    search_value: user.id,
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const [total, setTotal] = useState(0);

  useEffect(() => {
    isMounted && getList(searchParams);
  }, [isMounted, searchParams]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({ ...searchParams, current_page: page });
  };

  const { mutate: getList, isLoading } = useMutation(
    user.is_business
      ? client.transactions.kwickpayBuisiness
      : client.transactions.kwickpayIndividual,
    {
      onSuccess: (data) => {
        console.log(data);
        setTotal(data.total);

        setDataSource(
          data.data.map((item) => ({
            key: item.id,
            id: item.id,
            amount: item.amount,
            currency: item.currency,
            sender_name: item.sender_name,
            sender_country: item.sender_country,
            status: item.status,
            created_at: item.created_at,
            updated_at: item.updated_at,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onViewInvoice = (key: number) => {
    const filterInvoices = dataSource.filter((item) => item.key === key);
    if (filterInvoices.length === 0) {
      return;
    }

    const selInvoice = filterInvoices[0];
    onSelectedInvoiceData(selInvoice);
    /*
    setInvoiceFromPage(routes.users);
    selInvoice.invoice_data &&
      navigate(routes.invoice, { state: { info: selInvoice } });
    */
  };

  const columns: ColumnsType<IKwickpayTransaction> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          ID
        </p>
      ),
      dataIndex: "id",
      className: "w-auto",
      key: "id",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Indicated amount
        </p>
      ),
      dataIndex: "indicated_amount",
      className: "w-auto",
      key: "indicated_amount",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Fee
        </p>
      ),
      dataIndex: "fee",
      className: "w-auto",
      key: "fee",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Amount
        </p>
      ),
      dataIndex: "amount",
      className: "w-auto",
      key: "amount",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency
        </p>
      ),
      dataIndex: "currency",
      className: "w-auto",
      key: "currency",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Sender's name
        </p>
      ),
      dataIndex: "sender_name",
      className: "w-auto",
      key: "sender_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Sender's country
        </p>
      ),
      dataIndex: "sender_country",
      className: "w-auto",
      key: "sender_country",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Status
        </p>
      ),
      dataIndex: "status",
      className: "w-auto",
      key: "status",
      render: (value) => {
        return (
          <div
            className={`bg-[${
              value === "sent_to_iban" ? "#7F8172" : "#E6E8EC"
            }] inline-block py-[4px] px-[12px] rounded-[10px]`}
          >
            <p
              className={`font-manrope ${
                value === "sent_to_iban" ? "text-[#fff]" : "text-primary"
              } text-[12px] font-medium leading-none`}
            >
              {value && capitalizeFirstLetter(value.replaceAll("_", " "))}
            </p>
          </div>
        );
      },
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Created
        </p>
      ),
      dataIndex: "created_at",
      className: "w-auto",
      key: "created_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {moment(value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Updated
        </p>
      ),
      dataIndex: "updated_at",
      className: "w-auto",
      key: "updated_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {moment(value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<IKwickpayTransaction> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Transaction History
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-row gap-2 justify-between">
            <div className="w-full flex flex-col items-start gap-2">
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  ID
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.id}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Indicated amount
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.indicated_amount || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Fee
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.fee || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Amount
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.amount || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Currency
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.currency || "---"}
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Sender's name
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.sender_name || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Sender's country
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.sender_country || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Status
                </p>
                <div
                  className={`bg-[${
                    selData.status === "sent_to_iban" ? "#7F8172" : "#E6E8EC"
                  }] inline-block py-[4px] px-[12px] rounded-[10px]`}
                >
                  <p
                    className={`font-manrope ${
                      selData.status === "sent_to_iban"
                        ? "text-[#fff]"
                        : "text-primary"
                    } text-[12px] font-medium leading-none`}
                  >
                    {selData.status &&
                      capitalizeFirstLetter(
                        selData.status.replaceAll("_", " ")
                      )}
                  </p>
                </div>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Created
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {moment(selData.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  Updated
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {moment(selData.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                </p>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <div className="p-2 relative">
        <Table
          loading={isLoading}
          showSorterTooltip={false}
          columns={isMobile ? mobileColumns : columns}
          dataSource={dataSource}
          pagination={{
            total,
            current: searchParams.current_page,
            onChange: onPageChange,
            position: ["bottomCenter"],
            defaultPageSize: LIST_PAGE_SIZE,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
};

export default TransactionsKwickpayPage;
