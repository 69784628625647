export const SearchIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 25 25" {...props}>
      <path
        opacity={0.5}
        d="M24 24.2L17.6 17.8M11.2 21C5.89804 21 1.59998 16.702 1.59998 11.4C1.59998 6.09812 5.89804 1.80005 11.2 1.80005C16.5019 1.80005 20.8 6.09812 20.8 11.4C20.8 16.702 16.5019 21 11.2 21Z"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
};
