export function CurrencyIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.66663 10C6.66663 10.6417 7.16663 11.1667 7.77496 11.1667H9.02496C9.55829 11.1667 9.99163 10.7083 9.99163 10.15C9.99163 9.54167 9.72496 9.325 9.33329 9.18333L7.33329 8.48333C6.93329 8.34167 6.66663 8.125 6.66663 7.51667C6.66663 6.95833 7.09996 6.5 7.63329 6.5H8.88329C9.49996 6.50833 9.99996 7.025 9.99996 7.66667"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33337 11.2083V11.8249"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33337 5.84155V6.49155"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.32496 15.4834C12.0023 15.4834 14.9833 12.5024 14.9833 8.82508C14.9833 5.14779 12.0023 2.16675 8.32496 2.16675C4.64766 2.16675 1.66663 5.14779 1.66663 8.82508C1.66663 12.5024 4.64766 15.4834 8.32496 15.4834Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8167 17.0666C11.5667 18.1249 12.7917 18.8166 14.1917 18.8166C16.4667 18.8166 18.3166 16.9666 18.3166 14.6916C18.3166 13.3083 17.6333 12.0833 16.5917 11.3333"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
