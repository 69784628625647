import { FC } from "react";

const styles = {
  body: {
    fontFamily: "Manrope",
    padding: 50,
    paddingBottom: 50,
    paddingTop: 50,
    // width: '900px',
    height: "785px",
  },
  logo: {
    alignItems: "center",
    fontSize: 24,
    width: 40,
    height: 40,
  },
  logoText: {
    margin: 12,
    fontSize: 37,
    // fontFamily: 'Inter',
  },
  title: {
    fontSize: 24,
    marginTop: 60,
  },
  img: {
    width: 66,
    fontSize: 24,
    marginRight: 25,
    marginTop: 24,
    height: 34,
  },
  itemBlock: {
    fontFamily: "Manrope",
    display: "flex",
    justifyContent: "space-between",
    fontSize: 16,
    gap: 16,
  },
  container: {},
  itemTextLeft: {
    color: "#777E90",
  },
  text: {
    fontSize: 16,
  },
  block: {
    marginTop: 50,
    marginBottom: 50,
  },
};

type PDFFileDownloadProps = {
  data: any; //phone & email
  amount: number;
  currency: string;
};

const PDFFileDownload: FC<PDFFileDownloadProps> = ({
  data,
  amount,
  currency,
}) => {
  return (
    <div style={styles.body} className="pdf-container">
      <div className="invoice_body">
        <div style={styles.container} className="container">
          <div className="logo">
            <span className="logo__img">
              <img
                src={"/assets/logo.png"}
                alt="logo"
                className="header_logo"
                style={styles.logo}
              />
            </span>
            <span
              className="logo__text logo__text--invoice"
              style={styles.logoText}
            >
              Fastoo
            </span>
          </div>
          <p className="invoice_title">Deposit by Bank Transfer</p>
          <div className="invoice_item-block">
            <span className="invoice_item-text--left">Indicated Amount:</span>
            <span>{`   ${amount} ${currency.toUpperCase()}`}</span>
          </div>
          <div style={styles.block} className="invoice_block">
            {data.map((item: any, index: number) => (
              <div
                key={`pdf_info_${index}`}
                style={styles.itemBlock}
                className="invoice_item-block"
              >
                <div
                  style={styles.itemTextLeft}
                  className="invoice_item-text--left"
                >
                  {item.name}
                </div>
                <div className="invoice_text"> {item.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFFileDownload;
