import { Fragment, useContext } from "react";
import Avatar from "react-avatar";
import { AccountIcon } from "../components/icons/account-icon";
import routes from "../config/routes";
import Button from "../components/ui/button";
import Hamburger from "../components/ui/hamburger";
import ThemeSwitcher from "../components/ui/theme-switcher";
import ThemeContext from "../lib/theme/theme-context";
import { useNavigate } from "react-router-dom";
import { NavBalanceIcon } from "src/components/icons/balance-nav-icon";
import { useLocation } from "react-router-dom";
import useAuth from "../hooks/use-auth";
import { LogoutIcon } from "src/components/icons/logout-icon";
import { useLogout } from "../hooks/user";

function AuthorizedMenu({ email }: { email: string }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { unauthorize } = useAuth();
  const { mutate: logout } = useLogout();

  const onLogout = () => {
    //unauthorize();
    setTimeout(() => {
      logout();
      navigate(routes.home);
    }, 1200);
  };

  return (
    <div className="flex gap-[8px] sm:gap-[20px]">
      <Button
        variant="icon"
        className="gap-2 flex items-center justify-start group"
        onClick={onLogout}
      >
        <p className="text-primary group-hover:text-error font-manrope text-[14px] font-medium leading-[16px] hidden sm:block">
          {email}
        </p>
        <LogoutIcon className="w-[20px] h-[20px] text-primary group-hover:text-error" />
      </Button>
    </div>
  );
}

interface HeaderProps {
  isCollapse?: boolean;
  showHamburger?: boolean;
  onClickHamburger?: () => void;
}

export default function Header({
  isCollapse,
  showHamburger = false,
  onClickHamburger,
}: HeaderProps) {
  const { currentTheme, changeCurrentTheme } = useContext(ThemeContext);
  const isDarkMode = currentTheme === "dark";
  const navigate = useNavigate();
  const { isAuthorized, getCurrrentUser } = useAuth();

  return (
    <header className="app-header sticky top-0 z-30 flex h-16 w-full items-center justify-between border-b border-light-300 bg-light py-1 px-4 ltr:left-0 rtl:right-0 dark:border-dark-300 dark:bg-dark-250 sm:h-[70px] sm:px-6">
      <div className="flex items-center gap-2 flex-shrink-0 cursor-pointer">
        <a
          onClick={() => {
            navigate(routes.home);
          }}
        >
          <img
            src={
              isDarkMode ? "/assets/nav_logo_dark.png" : "/assets/nav_logo.png"
            }
            className="object-contain w-[113px] h-[32.78px]"
          />
        </a>
      </div>
      <div className="relative flex items-center gap-[12px]">
        {/* <ThemeSwitcher /> */}
        {isAuthorized ? (
          <AuthorizedMenu email={getCurrrentUser().email} />
        ) : (
          <></>
        )}
        {showHamburger && (
          <Hamburger
            isToggle={isCollapse}
            onClick={onClickHamburger}
            className="flex sm:hidden"
          />
        )}
      </div>
    </header>
  );
}
