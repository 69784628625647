import { Navigate } from "react-router-dom";

import routes from "../config/routes";
import useAuth from "../hooks/use-auth";

interface IPrivateRoute {
  permissions?: string[];
}

export default function PrivateRoute({
  children,
  permissions,
}: React.PropsWithChildren<IPrivateRoute>) {
  const { isAuthorized, checkPermission } = useAuth();

  if (!isAuthorized || (permissions && !checkPermission(permissions)))
    return <Navigate to={routes.home} />;

  return <>{children}</>;
}
