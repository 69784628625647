export function CurrencyCNYIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 384.000000 384.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,384.000000) scale(0.100000,-0.100000)"
        fill="#002fd7"
        stroke="none"
      >
        <path
          d="M1670 3830 c-545 -76 -1030 -374 -1338 -823 -137 -199 -247 -459
-300 -707 -25 -117 -26 -141 -26 -380 0 -239 1 -263 26 -380 165 -769 739
-1343 1508 -1508 117 -25 141 -26 380 -26 239 0 263 1 380 26 769 165 1343
739 1508 1508 25 117 26 141 26 380 0 239 -1 263 -26 380 -107 501 -389 925
-801 1208 -198 136 -460 247 -702 298 -104 22 -150 26 -345 29 -124 2 -254 0
-290 -5z m21 -1136 c219 -402 233 -425 245 -403 7 13 111 204 232 424 l218
400 188 3 c157 2 187 0 183 -11 -3 -8 -127 -238 -276 -510 -149 -273 -271
-499 -271 -502 0 -3 115 -5 255 -5 l255 0 0 -170 0 -170 -315 0 -315 0 0 -100
0 -100 315 0 315 0 0 -165 0 -165 -315 0 -315 0 0 -250 0 -250 -165 0 -165 0
0 250 0 250 -320 0 -320 0 0 165 0 165 320 0 320 0 0 100 0 100 -317 2 -318 3
0 165 0 165 257 5 257 5 -238 437 c-132 241 -258 471 -280 513 l-42 75 190 0
189 0 233 -426z"
        />
      </g>
    </svg>
  );
}
