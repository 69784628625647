import type { AdminUser } from "../api/types";
import useAuth from "./use-auth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import client from "../api";
import { API_ENDPOINTS } from "../api/endpoints";
import { error } from "console";

export function useLogout() {
  const { unauthorize } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(client.auth.logout, {
    onSuccess: () => {
      unauthorize();
    },
    onError: (error) => {
      unauthorize();
    },
  });
}
