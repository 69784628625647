export const OurPricesIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => {
  return (
    <svg fill="none" viewBox="0 0 22 22" {...props}>
      <path
        d="M7.66602 7.83366H8.99935M12.9993 13.167H14.3327M14.3327 7.16699L7.66602 13.8337M10.068 1.55279L8.56259 3.05815C8.31557 3.30517 7.98054 3.44395 7.6312 3.44395H5.26016C4.5327 3.44395 3.94297 4.03367 3.94297 4.76114V7.13218C3.94297 7.48152 3.80419 7.81655 3.55717 8.06357L2.05181 9.56893C1.53742 10.0833 1.53742 10.9173 2.05181 11.4317L3.55717 12.9371C3.80419 13.1841 3.94297 13.5191 3.94297 13.8685V16.2395C3.94297 16.967 4.5327 17.5567 5.26016 17.5567H7.6312C7.98054 17.5567 8.31557 17.6955 8.56259 17.9425L10.068 19.4479C10.5823 19.9623 11.4163 19.9623 11.9307 19.4479L13.4361 17.9425C13.6831 17.6955 14.0182 17.5567 14.3675 17.5567H16.7385C17.466 17.5567 18.0557 16.967 18.0557 16.2395V13.8685C18.0557 13.5191 18.1945 13.1841 18.4415 12.9371L19.9469 11.4317C20.4613 10.9173 20.4613 10.0833 19.9469 9.56893L18.4415 8.06357C18.1945 7.81655 18.0557 7.48152 18.0557 7.13218V4.76114C18.0557 4.03367 17.466 3.44395 16.7385 3.44395H14.3675C14.0182 3.44395 13.6831 3.30517 13.4361 3.05815L11.9307 1.55279C11.4163 1.03839 10.5823 1.03839 10.068 1.55279Z"
        stroke="#7F8172"
        stroke-width="1.5"
      ></path>
    </svg>
  );
};
