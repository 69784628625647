import {
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import { Switch } from "@headlessui/react";
export interface SwitchElement {
  isChecked: () => void;
}

const ToggleSwitch = forwardRef(
  (
    props: { type: string; value: boolean; onValueChanged: any, disabled?: boolean },
    ref: Ref<SwitchElement>
  ) => {
    const [toggled, setToggled] = useState(props.value);

    function isChecked() {
      return toggled;
    }

    useImperativeHandle(ref, () => ({ isChecked }));

    useEffect(() => {
      setToggled(props.value);
    }, [props.value]);

    return (
      <Switch
        checked={toggled}
        disabled={props.disabled}
        onChange={(checked) => {
          console.log(checked);
          //setToggled(checked);
          props.onValueChanged(props.type, checked);
        }}
        className={`${
          toggled ? "bg-[#34C759]" : "bg-secondary"
        } relative inline-flex h-8 w-14 items-center rounded-full flex-shrink-0`}
      >
        <span
          className={`${
            toggled ? "translate-x-7" : "translate-x-1"
          } inline-block h-6 w-6 transform rounded-full bg-white transition`}
        />
      </Switch>
    );
  }
);
ToggleSwitch.displayName = "ToggleSwitch";

export default ToggleSwitch;
