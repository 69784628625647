import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import { convertPaymentSystem } from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, InputNumber, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import {
  DailyLimit,
  Fee,
  Limitation,
  PaginationInput,
  User,
} from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import {
  DailyLimitDataType,
  FeeDataType,
  LimitationDataType,
  UserDataType,
} from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import { LockIcon } from "src/components/icons/lock-icon";
import { UnlockIcon } from "src/components/icons/unlock-icon";
import Button from "src/components/ui/button";
import { BackCircleIcon } from "src/components/icons/back-circle-icon";
import UsersContainerPage from "src/components/users";
import { EditIcon } from "src/components/icons/edit-icon";
import { CloseIcon } from "src/components/icons/close-icon";
import { CheckIcon } from "src/components/icons/check-icon";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: FeeDataType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const DailyLimitationsPage: FC = () => {
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isSmall, setIsSmall] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: DailyLimitDataType) => record.key === editingKey;
  const { openModal, closeModal } = useModalAction();
  const [total, setTotal] = useState(0);

  const [dataSource, setDataSource] = useState<DailyLimitDataType[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [updatedData, setUpdatedData] = useState<DailyLimitDataType | null>(
    null
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
    setIsSmall(window.innerWidth < 480 ? true : false);
  }

  const { mutate: getList, isLoading } = useMutation(client.dailyLimits.all, {
    onSuccess: (data) => {
      const sortData = data.sort(
        (a: any, b: any) => a.asset_code - b.asset_code
      );
      return setDataSource(
        sortData.map((item) => ({
          key: item.id,
          ...item,
        }))
      );
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const editOnMobile = (key: number) => {
    const selData = dataSource.filter((item) => item.key === key)[0];
    openModal("UPDATE_DAILY_LIMITATIONS_VIEW", {
      ...selData,
      callback: onCallBack,
    });
  };

  const onCallBack = (data: DailyLimit) => {
    console.log(data);
    setEditingKey(String(data.id));
    setUpdatedData({ key: data.id, ...data });

    setTimeout(() => {
      updateLimitations(data);
    }, 100);
  };

  const { mutate: updateLimitations, isLoading: isLoadingUpdate } = useMutation(
    client.dailyLimits.update,
    {
      onSuccess: (data) => {
        console.log(data);
        if (updatedData) {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => editingKey == item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...updatedData!,
            });
            setDataSource(newData);
            setEditingKey("");
          } else {
            newData.push(updatedData!);
            setDataSource(newData);
            setEditingKey("");
          }
        } else {
          setEditingKey("");
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    isMounted && getList();
  }, [isMounted]);

  const edit = (record: Partial<DailyLimitDataType> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key as string);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as DailyLimitDataType;
      console.log(row);
      setUpdatedData(row);

      setTimeout(() => {
        updateLimitations({ ...row, id: key as number });
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[12px] font-medium">
          Currency name
        </p>
      ),
      dataIndex: "asset_code",
      key: "asset_code",
      className: "w-auto",
      editable: false,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value?.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[12px] font-medium">
          Channel Name
        </p>
      ),
      dataIndex: "channel_name",
      key: "channel_name",
      className: "w-auto",
      editable: false,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {convertPaymentSystem(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[12px] font-medium">
          Deposit max
        </p>
      ),
      dataIndex: "deposit_max",
      key: "deposit_max",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[12px] font-medium">
          Withdraw max
        </p>
      ),
      dataIndex: "withdraw_max",
      key: "withdraw_max",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[12px] font-medium">
          VIP Deposit max
        </p>
      ),
      dataIndex: "vip_deposit_max",
      key: "vip_deposit_max",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[12px] font-medium">
          VIP Withdraw max
        </p>
      ),
      dataIndex: "vip_withdraw_max",
      key: "vip_withdraw_max",
      className: "w-auto",
      editable: true,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_: any, record: DailyLimitDataType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="flex flex-row gap-2">
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={cancel}
            >
              <CloseIcon className="w-[20px] h-[20px] text-secondary" />
            </button>
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => save(record.key)}
            >
              <CheckIcon className="w-[20px] h-[20px] text-primary" />
            </button>
          </div>
        ) : (
          <div className="flex flex-row gap-2">
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => edit(record)}
            >
              <EditIcon className="w-[20px] h-[20px] text-primary" />
            </button>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DailyLimitDataType) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: "",
        editing: isEditing(record),
      }),
    };
  });

  const mobileColumns: ColumnsType<DailyLimitDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-row gap-4 items-start justify-between">
            <div className="w-full flex flex-col items-start gap-2">
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Currency
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {selData?.asset_code?.toUpperCase()}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Channel Name
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {selData?.channel_name}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Deposit max
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(String(selData.deposit_max), 2)}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Withd.max
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(selData.withdraw_max, 2)}
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  VIP Dept.max
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(
                    String(selData.vip_deposit_max),
                    2
                  )}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  VIP With.max
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(selData.vip_withdraw_max, 2)}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium invisible">
                  Edit
                </p>
                <a
                  className="text-secondary underline hover:underline hover:text-primary text-[14px] font-manrope font-medium"
                  onClick={() => editOnMobile(record.key as number)}
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const smallColumns: ColumnsType<DailyLimitDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-2">
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Currency
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.asset_code?.toUpperCase()}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Channel Name
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.channel_name}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Dept.max
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(String(selData.deposit_max), 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Withd.max
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.withdraw_max, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                VIP Dept.max
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(
                  String(selData.vip_deposit_max),
                  2
                )}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                VIP With.max
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {makeNumberWithFloatingDigits(selData.vip_withdraw_max, 2)}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium invisible">
                Edit
              </p>
              <a
                className="text-secondary underline hover:underline hover:text-primary text-[14px] font-manrope font-medium"
                onClick={() => editOnMobile(record.key as number)}
              >
                Edit
              </a>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white rounded-r-[12px] rounded-bl-[12px] w-full relative">
      <div className="p-4 relative">
        {isMobile ? (
          <Table
            loading={isLoading || isLoadingUpdate}
            showSorterTooltip={false}
            columns={isSmall ? smallColumns : mobileColumns}
            dataSource={dataSource}
            pagination={false}
          />
        ) : (
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              loading={isLoading || isLoadingUpdate}
              showSorterTooltip={false}
              columns={mergedColumns}
              dataSource={dataSource}
              pagination={false}
              rowClassName="editable-row"
            />
          </Form>
        )}
      </div>
    </div>
  );
};

export default DailyLimitationsPage;
