export function CurrencyUAHIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="#3C93FD" />
      <path
        d="M16 14.2305V12.8712H8V14.2305H8.98137C8.71933 14.5756 8.56005 15.0095 8.55491 15.4382C8.56005 17.0537 9.78292 18 12.0128 18C14.3096 18 15.5067 17.1164 15.5838 15.1611H13.6159C13.5851 16.0499 12.948 16.3897 12.0128 16.3897C11.0572 16.3897 10.492 15.9976 10.4868 15.3232C10.492 14.9363 10.6461 14.5547 10.975 14.2305H16ZM12.0539 8.61027C12.9583 8.61027 13.4155 8.99192 13.4104 9.57747C13.4155 9.98004 13.2306 10.3199 12.9377 10.6231H8V12.0086H16V10.6231H14.921C15.1574 10.2833 15.3064 9.88593 15.3012 9.46245C15.3064 7.94106 14.1297 7 12.0334 7C9.78805 7 8.76557 8.09791 8.68337 9.77091H10.6358C10.6564 9.0442 11.1034 8.61027 12.0539 8.61027Z"
        fill="#D8C759"
      />
    </svg>
  );
}
