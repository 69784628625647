export const ArrowDownIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <path d="M12.6673 5.6665L8.00065 10.3332L3.33398 5.6665" stroke="#7F8172" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    );
};




