import { Menu } from "@headlessui/react";
import { forwardRef, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Transition } from "./transition";
import { Fragment } from "react";
import { DropdownIcon } from "../icons/dropdown-icon";
import { GreenTickIcon } from "../icons/green-tick-icon";
import { animate } from "framer-motion";
import { capitalizeFirstLetter } from "../../lib/utils";

type DropdownProps = {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  data: any[];
  value: string;
  onChange: any;
};

const DropdownType = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      label,
      error,
      className,
      placeholder = "",
      inputClassName = "bg-transparent",
      data = [],
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const selectedIDRef = useRef(-1);
    const [selectedId, setSelectedId] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerOpened, setIsContainerOpened] = useState(false);

    useEffect(() => {
      const currentItem = data?.find((item) => item.code === value);
      setSelectedId(Number(currentItem?.key));
    }, [value, data]);

    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      btnRef.current?.addEventListener("click", cickedMenuButton);
      window.addEventListener("click", handler);

      return () => {
        window.removeEventListener("click", handler);
        window.removeEventListener("resize", updateWindowSize);
        btnRef.current?.removeEventListener("click", cickedMenuButton);
      };
    }, []);

    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsContainerOpened(false);
      }
    };

    function updateWindowSize() {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }

    useEffect(() => {
      setTimeout(() => menuRef.current?.scrollTo(0, selectedId * 40), 100);
    }, [selectedId, isContainerOpened]);

    function cickedMenuButton() {
      btnRef.current &&
        btnRef.current.ariaExpanded &&
        btnRef.current.ariaExpanded === "false" &&
        setIsContainerOpened(true);

      setTimeout(
        () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
        100
      );
    }

    useEffect(() => {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }, [containerRef.current]);
    console.log(data);
    // debugger;

    return (
      <div className={className} ref={containerRef}>
        <label className="block text-[16px] font-manrope font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-16px font-manrope font-medium text-secondary dark:text-light/70">
              {label}
            </span>
          )}
          <Menu>
            <Menu.Button
              ref={btnRef}
              className={classNames(
                "relative h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-16px font-manrope font-medium bg-transparent px-4 py-2 text-dark dark:text-light lg:px-5"
              )}
            >
              {({ open }) => (
                <>
                  <p
                    className={`${
                      selectedId < 0 ? "text-other" : "text-primary"
                    } text-left`}
                  >
                    {/* {selectedId < 0 ? placeholder : data[selectedId]} */}
                  </p>
                  <span className="absolute top-0 flex h-full w-[34px] cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-0 dark:text-dark-800 hover:dark:text-light-900 lg:w-9">
                    <svg
                      className={`stroke h-auto w-4 ${
                        open ? "rotate-180" : "rotate-0"
                      }`}
                      width="8"
                      height="5"
                      viewBox="0 0 8 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L4 4L7 1"
                        stroke="#424242"
                        stroke-linecap="square"
                      ></path>
                    </svg>
                  </span>
                </>
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={menuRef}
                className={`max-h-[216px] w-[200px] left-0 scrollbar-hide overflow-y-auto flex flex-col absolute z-30  rounded-[12px] bg-light py-1.5 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light`}
                // style={{ width: containerWidth }}
              >
                {data.length &&
                  data.map((item, index) => {
                    console.log(item);
                    return (
                      <Menu.Item key={index}>
                        {({ active, close }) => (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              if (index == selectedId) {
                                close();
                                return;
                              }

                              setSelectedId(index);
                              onChange(item);
                              selectedIDRef.current = index;

                              close();
                            }}
                            className="flex transition-fill-colors px-4  flex-row items-start justify-between py-2 hover:bg-light-400"
                          >
                            <p
                              className={` text-[16px] font-medium font-manrope ${
                                active || selectedId === index
                                  ? "text-primary"
                                  : "text-[#7F8172]"
                              }`}
                            >
                              {item.name}
                            </p>
                            <GreenTickIcon
                              className={`${
                                active || selectedId === index
                                  ? "block"
                                  : "hidden"
                              } w-[20px] h-[20px]`}
                            />
                          </button>
                        )}
                      </Menu.Item>
                    );
                  })}
              </Menu.Items>
            </Transition>
          </Menu>
        </label>
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

DropdownType.displayName = "DropdownType";
export default DropdownType;
