export const DashboardIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 22 22" {...props}>
      <path
        d="M18.25 12.8333H13.8334C13.2811 12.8333 12.8334 13.281 12.8334 13.8333V18.2499C12.8334 18.8022 13.2811 19.2499 13.8334 19.2499H18.25C18.8023 19.2499 19.25 18.8022 19.25 18.2499V13.8333C19.25 13.281 18.8023 12.8333 18.25 12.8333Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16667 12.8333H3.75C3.19772 12.8333 2.75 13.281 2.75 13.8333V18.2499C2.75 18.8022 3.19771 19.2499 3.75 19.2499H8.16667C8.71895 19.2499 9.16667 18.8022 9.16667 18.2499V13.8333C9.16667 13.281 8.71895 12.8333 8.16667 12.8333Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.25 2.75H13.8334C13.2811 2.75 12.8334 3.19771 12.8334 3.75V8.16667C12.8334 8.71895 13.2811 9.16667 13.8334 9.16667H18.25C18.8023 9.16667 19.25 8.71895 19.25 8.16667V3.75C19.25 3.19772 18.8023 2.75 18.25 2.75Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16667 2.75H3.75C3.19772 2.75 2.75 3.19771 2.75 3.75V8.16667C2.75 8.71895 3.19771 9.16667 3.75 9.16667H8.16667C8.71895 9.16667 9.16667 8.71895 9.16667 8.16667V3.75C9.16667 3.19772 8.71895 2.75 8.16667 2.75Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
