export const UnidentifiedDepositsIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.06641 9.5C1.06641 5.11854 4.61828 1.56667 8.99974 1.56667C11.5282 1.56667 13.7803 2.74949 15.2331 4.59199M16.9331 9.5C16.9331 13.8815 13.3812 17.4333 8.99974 17.4333C6.47131 17.4333 4.21915 16.2505 2.76641 14.408M6.16641 14.0333H2.19974V18M15.7997 1V4.96667L11.8331 4.96667"
        stroke="#232800"
        stroke-width="1.5"
      />
    </svg>
  );
};
