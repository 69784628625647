export function NotificationIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5586 3.1667C11.3002 3.0917 11.0336 3.03337 10.7586 3.00003C9.95855 2.90003 9.19189 2.95837 8.47522 3.1667C8.71689 2.55003 9.31689 2.1167 10.0169 2.1167C10.7169 2.1167 11.3169 2.55003 11.5586 3.1667Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.517 16.3833C12.517 17.7583 11.392 18.8833 10.017 18.8833C9.33363 18.8833 8.7003 18.6 8.2503 18.15C7.8003 17.7 7.51697 17.0666 7.51697 16.3833"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path
        d="M10.0167 2.9248C7.25841 2.9248 5.01674 5.16647 5.01674 7.9248V10.3331C5.01674 10.8415 4.80007 11.6165 4.54174 12.0498L3.58341 13.6415C2.99174 14.6248 3.40007 15.7165 4.48341 16.0831C8.07507 17.2831 11.9501 17.2831 15.5417 16.0831C16.5501 15.7498 16.9917 14.5581 16.4417 13.6415L15.4834 12.0498C15.2334 11.6165 15.0167 10.8415 15.0167 10.3331V7.9248C15.0167 5.1748 12.7667 2.9248 10.0167 2.9248Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </svg>
  );
}
