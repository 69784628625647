import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getCurrencyInfo,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, InputNumber, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  SUCCESS_MODAL_TYPE,
} from "src/lib/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAuth from "src/hooks/use-auth";
import Password from "../ui/password";
import Button from "../ui/button";
import { TwoFactorAuthIcon } from "../icons/2fa-icon";
import TwoAuthSettingsConfiguration, {
  ConfigurationItem,
} from "./two-auth-configuration";

const TwoFactorAuthPage: FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal, closeModal } = useModalAction();
  const codeRef = useRef<ConfigurationItem>(null);

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { getCurrrentUser, setCurrentUser } = useAuth();

  const [is2FAEnabled, set2FAEnabled] = useState(
    getCurrrentUser().google2fa_enabled
  );
  const [secretKey, setSecretKey] = useState("");
  const [qrImage, setQRImage] = useState("");
  const [showConfiguration, setShowConfiguration] = useState(false);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const onEnable2FA = () => {
    if (!showConfiguration) {
      if (is2FAEnabled) {
        setShowConfiguration(!showConfiguration);
      } else {
        generateSecretKey();
      }
    } else {
      setShowConfiguration(!showConfiguration);
    }
  };

  const { mutate: generateSecretKey, isLoading: isLoadingSecretKey } =
    useMutation(client.google2FA.generateKey, {
      onSuccess: (data) => {
        console.log(data);
        setSecretKey(data.secret);
        setQRImage(data.QR_Image);
        setShowConfiguration(!showConfiguration);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    });

  return (
    <div className="bg-white rounded-l-[12px] rounded-br-[12px] sm:rounded-[12px] w-full">
      <div className="flex flex-col bg-white rounded-[12px] p-4 gap-4">
        <div className="flex flex-col items-start gap-4 w-full md:w-[567px]">
          <div className="w-full flex flex-row gap-2 items-center">
            <TwoFactorAuthIcon className="w-[24px] h-[24px] text-primary" />
            <p className="font-manrope text-[18px] font-medium text-primary leading-[24px]">
              Two-Factor Authentication
            </p>
          </div>
          <div className="w-full">
            <hr className="h-[1px] border-0 bg-[#E6E8EC] opacity-50 dark:opacity-50" />
          </div>

          <p className="font-manrope text-secondary text-[16px] font-medium leading-[24px]">
            For your security, we strongly recommend enabling 2FA on your
            account. After that, you will need to enter a one-time 6-digit code
            each time you are logging into your account, withdrawing funds or
            changing the password.
          </p>
          <Button
            variant="solid"
            onClick={onEnable2FA}
            className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full bg-active hover:bg-main"
            isLoading={isLoadingSecretKey}
          >
            {`${is2FAEnabled ? "Disable" : "Enable"} 2FA`}
          </Button>
        </div>

        {showConfiguration ? (
          <TwoAuthSettingsConfiguration
            isEnabled={is2FAEnabled}
            secretKey={secretKey}
            qrCode={qrImage}
            ref={codeRef}
            set2FAEnabled={set2FAEnabled}
            setShowConfiguration={setShowConfiguration}
          />
        ) : (
          <div className="h-[120px]" />
        )}
      </div>
    </div>
  );
};

export default TwoFactorAuthPage;
