import { FC, useContext, useEffect, useState } from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  getErrorMessage,
  splitLetettLetter,
  capitalizeFirstLetter,
  getEmailStatusStyleName,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { DateRangePicker } from "rsuite";
import { useDateTimePicker } from "src/lib/hooks/useDateTimePicker";
import { Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  phoneHistoryFilterOptions,
} from "src/lib/constants";
import { PaginationInput } from "src/api/types";

import { IEmailHistoryItem } from "src/api/types";
import moment from "moment";
import { currentTimeForDataPicker } from "src/lib/utils";
import "react-swipeable-list/dist/styles.css";
import SearchFilter from "src/components/search-filters/reset2fa-filter";

const SmsHistoryPage: FC = () => {
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [filter, setFilter] = useState("user_id");
  const [searchText, setSearchText] = useState("");

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<IEmailHistoryItem[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const { afterToday } = DateRangePicker;

  const onDateSelect = (_startDate: string | null, _endDate: string | null) => {
    setStartDate(_startDate);
    setEndDate(_endDate);
  };

  const {
    dateRangeValue,
    timeRangeValue,
    changeDateRangeHandler,
    changeTimeRangeHandler,
  } = useDateTimePicker({ onDateSelect });

  const now = new Date();
  const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const { mutate: getList, isLoading } = useMutation(
    client.users.getSmsHistoryList,
    {
      onSuccess: (data) => {
        if (data?.status) {
          setAlertText("Export success");
          setIsAlertOpened(true);
          return;
        }
        setTotal(data.total);
        if (data) {
          const newData = [...data.data];
          setDataSource(
            newData.map((item: any) => ({
              key: item.id,
              ...item,
            }))
          );
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted) {
        getList({
          ...searchParams,
          search_value: searchText.length > 0 ? searchText : undefined,
          search_field: searchText.length > 0 ? filter : undefined,
          start_date: startDate || undefined,
          end_date: endDate || undefined,
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [
    isMounted,
    searchParams.current_page,
    searchText,
    filter,
    startDate,
    endDate,
    timeRangeValue,
  ]);

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({ ...searchParams, current_page: page });
  };

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        getList({
          ...searchParams,
          search_value: searchText || undefined,
          search_field: searchText ? filter : undefined,
          start_date: startDate || undefined,
          end_date: endDate || undefined,
          export: 1,
        });
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          User ID
        </p>
      ),
      dataIndex: "user_id",
      key: "user_id",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Phone number
        </p>
      ),
      dataIndex: "phone",
      key: "phone",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Request Creation Time
        </p>
      ),
      dataIndex: "created_at",
      key: "created_at",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {value
              ? moment.unix(Number(value)).utc(true).format("DD-MM-YYYY H:mm")
              : value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Confirmation Time
        </p>
      ),
      dataIndex: "confirmed_at",
      key: "confirmed_at",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {value
              ? moment.unix(Number(value)).utc(true).format("DD-MM-YYYY H:mm")
              : ""}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Code
        </p>
      ),
      dataIndex: "code",
      key: "code",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Event type
        </p>
      ),
      dataIndex: "event",
      key: "event",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {capitalizeFirstLetter(
              value === "phone_init" ? "Add number" : value
            )}
          </p>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Status
        </p>
      ),
      dataIndex: "status",
      key: "status",
      className: "w-auto",
      editable: true,
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center rounded-full">
          <p
            className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getEmailStatusStyleName(
              String(value)
            )}`}
          >
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_: any, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                User ID
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.user_id}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Phone
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.phone}
              </p>
            </div>

            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Request Creation Time
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.created_at
                  ? moment
                      .unix(Number(selData?.created_at))
                      .utc(true)
                      .format("DD-MM-YYYY H:mm")
                  : ""}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Confirmation Time
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData?.confirmed_at
                  ? moment
                      .unix(Number(selData?.confirmed_at))
                      .utc(true)
                      .format("DD-MM-YYYY H:mm")
                  : ""}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Code
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.code}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Event type
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.event}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Status
              </p>
              <p
                className={`text-primary text-[14px] font-manrope font-medium ${getEmailStatusStyleName(
                  String(selData.status)
                )}`}
              >
                {selData.status}
              </p>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <div className="w-full flex flex-row items-center justify-between">
          <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
            SMS
          </p>
        </div>
        <div className="mt-4 flex items-center justify-between w-full mb-4">
          <SearchFilter
            options={phoneHistoryFilterOptions}
            value={filter}
            onChange={setFilter}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <div className="flex gap-2">
            <DateRangePicker
              format="dd/MM/yyyy"
              size="lg"
              block
              placement="bottomEnd"
              style={{ width: "308px" }}
              value={dateRangeValue}
              disabledDate={afterToday?.()}
              onChange={(range) => {
                setSearchParams({ ...searchParams, current_page: 1 });
                if (range) {
                  const currentTime: Date[] = currentTimeForDataPicker(range);
                  changeDateRangeHandler([currentTime[0], currentTime[1]]);
                } else {
                  changeDateRangeHandler(null);
                }
              }}
              defaultCalendarValue={[prevMonth, now]}
              ranges={[]}
            />

            <DateRangePicker
              format="HH:mm"
              size="lg"
              block
              placement="bottomEnd"
              style={{ width: "200px" }}
              value={timeRangeValue}
              onChange={(range) => {
                setSearchParams({ ...searchParams, current_page: 1 });
                changeTimeRangeHandler(range);
              }}
              defaultCalendarValue={[prevMonth, now]}
              ranges={[]}
            />
            <button
              type="button"
              onClick={() => exportBtn()}
              className="button button--type3 button--gap30"
            >
              Export Excel
              <span className="btn-icon btn-icon--fill">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.79961 1.8002L12.6996 5.4002M8.79961 1.8002L5.19961 5.4002M8.79961 1.8002L8.79961 13.2002M15.9996 8.4002V16.2002L1.59961 16.2002L1.59961 8.4002"
                    stroke="#232800"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
          <Table
            loading={isLoading}
            showSorterTooltip={false}
            columns={isMobile ? mobileColumns : columns}
            dataSource={dataSource}
            pagination={{
              total,
              current: searchParams.current_page,
              onChange: onPageChange,
              position: ["bottomCenter"],
              defaultPageSize: LIST_PAGE_SIZE,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SmsHistoryPage;
