export const QuestionIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 35 35" stroke="currentColor" {...props}>
      <path
        d="M17.5 31.6673C25.3241 31.6673 31.6667 25.3247 31.6667 17.5006C31.6667 9.67662 25.3241 3.33398 17.5 3.33398C9.67601 3.33398 3.33337 9.67662 3.33337 17.5006C3.33337 25.3247 9.67601 31.6673 17.5 31.6673Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M13.3774 13.2498C13.7105 12.303 14.3679 11.5046 15.2332 10.9961C16.0985 10.4876 17.1159 10.3017 18.1051 10.4713C19.0943 10.641 19.9916 11.1553 20.638 11.9232C21.2843 12.691 21.6381 13.6628 21.6366 14.6665C21.6366 17.4998 17.5 17.5 17.5 20"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M17.5 24.584H17.5092"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};
