/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { desktopOrLaptop, laptop, macLaptop } from "../../lib/constants";
import CustomTooltip from "./custom-tooltip";
import RenderActiveShape from "./active-shape";
import { IBalanceChartProps, IChartData } from "./types";

// ================================================:
const Chart: FC<IBalanceChartProps> = ({
  data,
  currentBalanceItemName,
  type,
}) => {
  const [activeBalanceItemIndex, setActiveBalanceItemIndex] = useState(0);

  useEffect(() => {
    const currentItemIndex = data?.findIndex(
      (el) => el.asset_code === currentBalanceItemName
    );

    setActiveBalanceItemIndex(currentItemIndex);
  }, [data, currentBalanceItemName]);

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-device-width: ${String(desktopOrLaptop)}px)`,
  });

  const isLaptop = useMediaQuery({
    query: `(min-device-width: ${String(laptop)}px)`,
  });

  const isMacLaptop = useMediaQuery({
    query: `(max-device-width: ${String(macLaptop)}px)`,
  });

  const pieChartRadius =
    (isLaptop && isMacLaptop && 100) ||
    (!isDesktopOrLaptop && 100) ||
    (!isLaptop && 100) ||
    150;

  const pieChartSize = (!isDesktopOrLaptop && 300) || 400;

  const COLORS = [
    "#ba82ec",
    "#9cc5f2",
    "#ffeaa0",
    "#f36161",
    "#2fe762",
    "#ff9999",
    "#ff9999",
  ];

  // const pieChartData: IChartData[] = [];

  // data?.forEach((item) => {
  // 	pieChartData.push({
  // 		name: item.asset_code,
  // 		value: Number(
  // 			item.balances.reduce((sum: number, elem: any) => {
  // 				return sum + Number(elem.balance_usdt);
  // 			}, 0),
  // 		),
  // 	});
  // });

  const [pieChartData, setPieChartData] = useState<IChartData[]>([]);

  useEffect(() => {
    const pieData: IChartData[] = [];
    data?.map((item) => {
      return pieData.push({
        name: item.asset_code,
        value: Number(item.balance_usd),
      });
    });

    setPieChartData(pieData);
  }, [data, type]);

  return (
    <div className="chart chart-custom">
      <div className="chart__wrapper">
        <ResponsiveContainer width="100%" height={pieChartSize}>
          <PieChart>
            <Pie
              data={pieChartData}
              cx="50%"
              cy="50%"
              activeIndex={activeBalanceItemIndex}
              activeShape={(props: any) => (
                <RenderActiveShape data={data} {...props} />
              )}
              outerRadius={pieChartRadius}
              fill="#8884d8"
              dataKey="value"
            >
              {pieChartData?.map((entry: IChartData, index: number) => (
                <Cell
                  key={`cell-${entry.name}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip data={data} />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Chart;
